import {
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react-dom-interactions";
import { useState } from "react";

interface Props {
  children: React.ReactNode;
}

function HoverableText({ children }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const { x, y, strategy, reference, context, floating } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(0), flip(), shift()],
  });

  const hover = useHover(context);
  const role = useRole(context, { role: "tooltip" });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    role,
  ]);

  return (
    <>
      <div
        {...getReferenceProps()}
        ref={reference}
        className="max-w-[180px] truncate"
      >
        {children}
      </div>
      {isOpen && (
        <div
          ref={floating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
          }}
          {...getFloatingProps()}
          className="absolute z-50 bg-white p-2 rounded-md shadow-md Tooltip text-dark"
        >
          {children}
        </div>
      )}
    </>
  );
}

export default HoverableText;
