import React from "react";
import TextInput from "./Text";
import { InputProps } from "./types";

// const re = /^[0-9\b]+$/
// accept decimal numbers
const re = /^[0-9\b.]+$/;

interface NumberProps extends Omit<InputProps, "value" | "onChange" | "ref"> {
  value: number;
  onChange: (val: number) => void;
}

const NumberInput = React.forwardRef(
  (props: NumberProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { value, onChange: onChangeProp, ...otherProps } = props;

    const onChange = (val: string) => {
      if (val === "" || re.test(val)) {
        onChangeProp(Number(val));
      }
    };
    return (
      <TextInput
        type="number"
        ref={ref}
        value={value.toString()}
        onChange={onChange}
        {...otherProps}
      />
    );
  }
);

export default NumberInput;
