import { Button, Chip, FormControl, Grid, MenuItem } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKModal from "components/GKModal";
import GKSelect from "components/GKSelect";
import GKTextField from "components/GKTextField";
import { useFormik } from "formik";
import { useState } from "react";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  refetch: any;
  brokerData: any;
}

const CreateBrokerModal = (props: Props) => {
  const { isModalOpen, setIsModalOpen, refetch, brokerData } = props;

  const [tagInput, setTagInput] = useState("");

  const { mutate: handleCreateBroker } = useMutation({
    mutationKey: ["CREATE_BROKEN"],
    mutationFn: (data: any) => axiosInstance.post("/broker/create/", data),
    onSuccess: (response) => {
      refetch();
      toast.success(response?.data?.message || "Create Broker Successfully");
      setIsModalOpen(false);
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const { mutate: handleUpdateBroker } = useMutation({
    mutationKey: ["UPDATE_BROKEN"],
    mutationFn: (data: any) =>
      axiosInstance.patch(`/broker/${brokerData?.id}/update/`, data),
    onSuccess: (response) => {
      refetch();
      toast.success(response?.data?.message || "Update Broker Successfully");
      setIsModalOpen(false);
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const formik = useFormik({
    initialValues: {
      name: brokerData?.id ? brokerData.name : "",
      status: brokerData?.id ? brokerData.status : "",
      meta: brokerData?.id ? JSON.parse(brokerData?.meta) : [],
    },
    onSubmit: async (values) => {
      if (brokerData?.id) {
        handleUpdateBroker({
          ...values,
          meta: `[${values.meta}]`,
          status: values.status === "true",
        });
      } else {
        handleCreateBroker({
          ...values,
          meta: `[${values.meta}]`,
          status: values.status === "true",
        });
      }
    },
  });

  const handleTagAdd = () => {
    if (tagInput.trim() !== "") {
      const tags = tagInput.split(",").map((tag) => tag.trim());
      formik.setFieldValue("meta", [...formik.values.meta, ...tags]);
      setTagInput("");
    }
  };

  const handleTagDelete = (tagIndex: number) => {
    const updatedTags = [...formik.values.meta];
    updatedTags.splice(tagIndex, 1);
    formik.setFieldValue("meta", updatedTags);
  };

  return (
    <GKModal
      open={isModalOpen}
      setOpen={setIsModalOpen}
      modalTitle={brokerData?.id ? "Update Broker" : "Add New Broker"}
      footer={
        <Grid container justifyContent={"flex-end"} gap={1}>
          <Button variant="outlined" onClick={() => setIsModalOpen(false)}>
            Close
          </Button>
          <Button type="submit" form="create-broker-form" variant="outlined">
            {brokerData?.id ? "Update" : "Submit"}
          </Button>
        </Grid>
      }
    >
      <form onSubmit={formik.handleSubmit} id="create-broker-form">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="name"
                onChange={formik?.handleChange}
                value={formik.values.name}
                name="name"
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="standard" fullWidth>
              <GKSelect
                inputLabel="Status"
                onChange={formik?.handleChange}
                value={formik.values.status}
                name="status"
              >
                <MenuItem value={"true"}>Active</MenuItem>
                <MenuItem value={"false"}>Inactive</MenuItem>
              </GKSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="meta"
                value={tagInput}
                onChange={(event) => setTagInput(event.target.value)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    handleTagAdd();
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} display={"flex"} gap={1}>
            {formik.values.meta.map((tag: any, index: number) => (
              <Grid item key={index}>
                <Chip label={tag} onDelete={() => handleTagDelete(index)} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </form>
    </GKModal>
  );
};

export default CreateBrokerModal;
