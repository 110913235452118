import { GridColDef } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import CommonPagination from "components/CommanPagination";
import GKTable from "components/GKTable";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField } from "utils/helpers";

const EquityBasketTable = () => {
  const {
    state: { commonFilter },
  } = useAppContext();

  const requestBody = {
    requireTotalCount: true,
    take: commonFilter?.equityBasket?.take,
    skip: commonFilter?.equityBasket?.skip,
    sort: JSON.stringify(
      commonFilter?.equityBasket?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.equityBasket?.search || "",
  };

  const { data, isFetching } = useQuery({
    queryKey: ["DASHBOARD_ADVISOR_TABLE", requestBody],
    queryFn: () =>
      axiosInstance.get(`/super_admin/model-portfolios/`, {
        params: requestBody,
      }),
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Name", "name", 150, 1),
      valueGetter: (row) => row?.row?.name || "-",
    },
    {
      ...extraColumnsField("Advisor Name", "advisor", 150, 1),
      valueGetter: (row) => row?.row?.advisor || "-",
    },
    {
      ...extraColumnsField("Versions", "versionsCount", 200, 1),
      type: "number",
      valueGetter: (row) => row?.row?.versionsCount || 0,
    },
    {
      ...extraColumnsField(
        "Mapped Portfolios",
        "mappedPortfoliosCount",
        150,
        1
      ),
      type: "number",
      valueGetter: (row) => row?.row?.mappedPortfoliosCount || 0,
    },
    {
      ...extraColumnsField("Unique Item", "uniqueItemCount", 120, 1),
      type: "number",
      valueGetter: (row) => row?.row?.uniqueItemCount || 0,
    },
    {
      ...extraColumnsField("Total Orders", "ordersCount", 120, 1),
      type: "number",
      valueGetter: (row) => row?.row?.ordersCount || 0,
    },
    {
      ...extraColumnsField("Allocation", "allocationCount", 120, 1),
      type: "number",
      valueGetter: (row) => row?.row?.allocationCount || 0,
    },
    {
      ...extraColumnsField("Rebalance", "rebalanceCount", 120, 1),
      type: "number",
      valueGetter: (row) => row?.row?.rebalanceCount || 0,
    },
    {
      ...extraColumnsField("Exit", "exitCount", 80, 1),
      type: "number",
      valueGetter: (row) => row?.row?.exitCount || 0,
    },
    {
      ...extraColumnsField("Company Wise Exit", "cwexitCount", 160, 1),
      type: "number",
      valueGetter: (row) => row?.row?.cwexitCount || 0,
    },
  ];

  const cusPagination = () => {
    return (
      <CommonPagination
        name="equityBasket"
        totalCount={data?.data.totalCount || 0}
      />
    );
  };

  return (
    <GKTable
      loading={isFetching}
      columns={columns}
      name="equityBasket"
      rows={
        data?.data?.data.map((dataItem: any, index: number) => {
          return {
            ...dataItem,
            id: index,
          };
        }) || []
      }
      CustomPagination={!isFetching && cusPagination}
    />
  );
};

export default EquityBasketTable;
