import { Grid, Typography } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface Props {
  handleChange: any;
  value: string;
  inputLabel: string;
}

const GKReactQuill = (props: Props) => {
  const { handleChange, value, inputLabel } = props;

  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "header",
    "blockquote",
    "code-block",
    "indent",
    "list",
    "direction",
    "align",
    "link",
    "image",
    "video",
    "formula",
  ];

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  return (
    <Grid container>
      <Grid item xs={12} mb={0.5}>
        <Typography>{inputLabel || "Title"}</Typography>
      </Grid>
      <Grid item xs={12}>
        <ReactQuill
          onChange={handleChange}
          value={value}
          modules={modules}
          formats={formats}
        />
      </Grid>
    </Grid>
  );
};

export default GKReactQuill;
