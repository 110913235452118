/* eslint-disable jsx-a11y/label-has-associated-control */
import { Listbox, Transition } from "@headlessui/react";
import classNames from "classnames";
import React from "react";
import { MdCheck, MdUnfoldMore } from "react-icons/md";

interface SelectOption {
  name: string;
  value: any;
  disabled?: boolean;
}

interface SelectProps {
  items: SelectOption[];
  value: any;
  placeholder?: any;
  label?: string;
  innerLabel?: boolean;
  onChange: (value: any) => void;
  className?: string;
  variant?: "filled" | "outlined";
  disabled?: boolean;
  height?: string;
}

const variantMap = {
  outlined: "bg-paper border border-default",
  filled: "bg-light focus:ring-2 focus:ring-primary-dark",
};

function Select(props: SelectProps) {
  const {
    items,
    value,
    placeholder,
    label,
    innerLabel,
    onChange,
    disabled = false,
    className,
    height,
    variant = "filled",
  } = props;

  const currentItem = items.find((x) => x.value === value);

  return (
    <div className={classNames(className, "group")}>
      {!innerLabel && label && (
        <label className="group-focus-within:text-primary-dark text-sm text-light font-semibold mb-1">
          {label}
        </label>
      )}
      <Listbox
        value={value}
        onChange={onChange}
        as={React.Fragment}
        disabled={disabled}
      >
        <div>
          <Listbox.Button
            className={classNames(
              "w-full py-3 px-4 rounded-lg flex items-center disabled:bg-dark disabled:cursor-not-allowed",
              variantMap[variant]
            )}
          >
            <div className="flex-grow">
              {innerLabel && label && (
                <label className="block my-1 text-xs leading-[0.5em] w-full text-left text-trace">
                  {label}
                </label>
              )}
              {currentItem ? (
                <div className="text-left">{currentItem.name}</div>
              ) : (
                <div className="text-light text-left">
                  {placeholder || "Select item"}
                </div>
              )}
            </div>
            <MdUnfoldMore className="ml-3 text-lg text-trace" />
          </Listbox.Button>
          <Transition
            as="div"
            className="relative z-50"
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Listbox.Options
              className={classNames(
                height || "max-h-36",
                "absolute mt-1 z-50 w-auto min-w-full whitespace-nowrap overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              )}
            >
              {items.map((item) => (
                <Listbox.Option
                  key={item.name}
                  value={item.value}
                  disabled={item.disabled}
                >
                  {({ selected, active }) => (
                    <div
                      className={classNames(
                        "p-2 cursor-pointer flex items-center",
                        selected
                          ? "bg-primary-light/20 font-medium text-primary-dark"
                          : active && "bg-light",
                        item.disabled && "text-light/50"
                      )}
                    >
                      <span className="w-6">
                        {selected && <MdCheck className="text-lg" />}
                      </span>
                      <span>{item.name}</span>
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}

export default Select;
