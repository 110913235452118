import CheckBox from "components/CheckBox";
import Heading from "components/Heading";
import Input from "components/Input";
import Select from "components/Select";
import React from "react";
import { MODEL_PORTFOLIO_REBALANCING_TIME } from "utils/constants";
import { dummySelectItems, highWaterMarkItems } from "./items";

interface Props {
  selected: string[];
}

function PageContent({ selected }: Props) {
  const [test, setTest] = React.useState<any>([]);
  const [initialState, setInitialState] = React.useState<any>({
    managementFee: 0,
    calculationMethod: "",
    calculationType: "",
    billingFrequency: "",
    calculationFrequency: "",
    outOfPocketExpenses: 0,
    minimumCharge: 0,
    maximumCharge: 0,
  });

  const isManagementFee = selected.includes("managementFee");
  const isPerformanceFee = selected.includes("performanceFee");

  return (
    <section className="grid grid-cols-12 gap-12">
      {/* mangement fee */}
      {isManagementFee && (
        <div className="col-span-6">
          <Heading
            heading="Management Fee"
            subHeading="Enter Management Fee"
            className="mb-4"
            border
          />
          <div className="grid grid-cols-6 gap-4">
            <Input.Number
              label="Management Fee (%)"
              placeholder="Enter Management Fee"
              className="col-span-3"
              value={initialState.managementFee}
              onChange={(e) =>
                setInitialState({ ...initialState, managementFee: e })
              }
            />
            <Select
              label="Calculation Method"
              placeholder="Select Calculation Method"
              className="col-span-3"
              items={dummySelectItems}
              value={initialState.calculationMethod}
              onChange={(e) =>
                setInitialState({ ...initialState, calculationMethod: e })
              }
            />
            <Input.Text
              label="Calculation Type"
              placeholder="Enter Calculation Type"
              className="col-span-3"
              value={initialState.calculationType}
              onChange={(e) =>
                setInitialState({ ...initialState, calculationType: e })
              }
            />
            <Select
              label="Billing Frequency"
              placeholder="Enter Calculation Type"
              className="col-span-3"
              items={MODEL_PORTFOLIO_REBALANCING_TIME}
              value={initialState.billingFrequency}
              onChange={(e) =>
                setInitialState({ ...initialState, billingFrequency: e })
              }
            />
            <Select
              label="Calculation Frequency"
              placeholder="Enter Calculation Type"
              className="col-span-3"
              items={MODEL_PORTFOLIO_REBALANCING_TIME}
              value={initialState.calculationFrequency}
              onChange={(e) =>
                setInitialState({ ...initialState, calculationFrequency: e })
              }
            />
            <Input.Number
              label="Out of Pocket Expenses"
              placeholder="Enter Out of Pocket Expenses"
              className="col-span-3"
              value={initialState.outOfPocketExpenses}
              onChange={(e) =>
                setInitialState({ ...initialState, outOfPocketExpenses: e })
              }
            />
            <Input.Number
              label="Minimum Charge"
              placeholder="Enter Minimum Charge"
              className="col-span-3"
              value={initialState.minimumCharge}
              onChange={(e) =>
                setInitialState({ ...initialState, minimumCharge: e })
              }
            />
            <Input.Number
              label="Maximum Charge"
              placeholder="Enter Maximum Charge"
              className="col-span-3"
              value={initialState.maximumCharge}
              onChange={(e) =>
                setInitialState({ ...initialState, maximumCharge: e })
              }
            />
          </div>
        </div>
      )}
      {/* Performance Fee */}
      {isPerformanceFee && (
        <div className="col-span-6 gap-4">
          <Heading
            heading="Performance Fee"
            subHeading="Enter Performance Fee"
            className="mb-4"
            border
          />
          <div className="grid grid-cols-6 gap-4">
            <Select
              label="Calculation Method"
              placeholder="Select Calculation Method"
              className="col-span-3"
              items={dummySelectItems}
              value={initialState.calculationMethod}
              onChange={(e) =>
                setInitialState({ ...initialState, calculationMethod: e })
              }
            />
            <Input.Text
              label="Calculation Type"
              placeholder="Enter Calculation Type"
              className="col-span-3"
              value={initialState.calculationMethod}
              onChange={(e) =>
                setInitialState({ ...initialState, calculationMethod: e })
              }
            />
            <Select
              label="Billing Frequency"
              placeholder="Enter Calculation Type"
              className="col-span-3"
              items={MODEL_PORTFOLIO_REBALANCING_TIME}
              value={initialState.billingFrequency}
              onChange={(e) =>
                setInitialState({ ...initialState, billingFrequency: e })
              }
            />
            <Input.Number
              label="Minimum Charge"
              placeholder="Enter Minimum Charge"
              className="col-span-3"
              value={initialState.minimumCharge}
              onChange={(e) =>
                setInitialState({ ...initialState, minimumCharge: e })
              }
            />

            <Input.Text
              label="Hurdle Rate"
              placeholder="Enter Hurdle Rate"
              className="col-span-3"
              value={initialState.calculationType}
              onChange={(e) =>
                setInitialState({ ...initialState, calculationType: e })
              }
            />
            <div className="col-span-3 mt-4">
              <CheckBox
                selected={test}
                setSelected={(e) => setTest(e)}
                items={highWaterMarkItems}
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default PageContent;
