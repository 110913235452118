import Checkbox from "components/CheckBox";
import Heading from "components/Heading";
import DashboardLayout from "layouts/DashboardLayout";
import React from "react";
import PageContent from "./PageContent";
import { items } from "./items";

function AddPlan() {
  const [selected, setSelected] = React.useState<string[]>([]);
  // const navigate = useNavigate();
  return (
    <DashboardLayout
      title="Add Plan"
      breadcrumbs={[
        {
          name: "Revenue Management",
          path: "",
        },
        {
          name: "Fee Structure",
          path: "/app/revenue-management/fee-structure",
        },
        {
          name: "Add Plan",
          path: "",
        },
      ]}
      // toolbar={
      //   <div className="gap-4 flex items-center">
      //     <Button className="px-3 py-2" onClick={() => navigate(-1)}>
      //       Back
      //       <TbArrowBackUp className="cursor-pointer ml-2 text-xl" />
      //     </Button>
      //   </div>
      // }
    >
      <div className="px-8 pt-4 shadow-md pb-8 bg-white rounded-lg mb-8">
        <Heading
          heading="Add Fee Plan"
          subHeading="Select Fee Plan"
          border
          className="mb-8"
        />
        <Checkbox
          selected={selected}
          setSelected={setSelected}
          items={items}
          className="w-full gap-8"
        />
        <section className="my-12">
          <PageContent selected={selected} />
        </section>
      </div>
    </DashboardLayout>
  );
}

export default AddPlan;
