import moment from "moment";
import { useMemo, useDeferredValue } from "react";
import { ProcessedDataItem, SortOrder, TableColumn } from "./types";

export const useProcessedData = <T>(
  columns: TableColumn<T>[],
  data: T[],
  query: string,
  sort: [string | null, SortOrder]
) => {
  const getCellData = (item: T, col: TableColumn<T>): string => {
    if (
      (col?.renderer && col?.name === "Today's Gain") ||
      col?.name === "Unrealized Gain"
    ) {
      try {
        // @ts-ignore
        return col?.renderer(item)?.props?.children[0]?.props?.children;
      } catch (error) {
        return "";
      }
    }
    if (col.key !== undefined) {
      return `${(item as any)[col.key]}`;
    }
    try {
      return `${col.get(item)}`;
    } catch (error) {
      return ``;
    }
  };

  const deferedData = useDeferredValue(data);

  const processedData = useMemo(
    () =>
      deferedData.reduce<ProcessedDataItem[]>((curr, item) => {
        const currentItem: ProcessedDataItem = {
          gridKeyDisplayValue: {},
          gridKeyQueryByData: {},
          gridKeySortByData: {},
        };
        columns.forEach((col) => {
          const cellData = getCellData(item, col);
          if (col.renderer) {
            currentItem.gridKeyDisplayValue[col.name] = col.renderer(item);
          } else {
            currentItem.gridKeyDisplayValue[col.name] = cellData;
          }

          // calculate data to sort by
          const sortable = col.sortable === undefined ? true : col.sortable;
          let sortData: number | string = cellData;

          if (col.type === "number")
            sortData = parseInt(cellData.replace(/[,|.]/g, ""), 10);
          if (col.type === "date")
            // set sort data to date but convert it from dd/mm/yyyy to unix timestamp
            sortData = parseInt(
              moment(cellData, "DD/MM/YYYY hh:mm:ss A").format("X"),
              10
            );
          if (sortable !== false) {
            currentItem.gridKeySortByData[col.name] =
              sortable === true ? sortData : sortable(item);
          }
          // calculate data to query by
          const search = col.search === undefined ? true : col.search;
          if (search !== false) {
            currentItem.gridKeyQueryByData[col.name] =
              search === true ? cellData : search(item);
          }
        });
        curr.push(currentItem);
        return curr;
      }, []),
    [columns, deferedData]
  );

  const deferedInput = useDeferredValue(query);

  const filteredData = useMemo(
    () =>
      processedData.filter((item) => {
        if (!deferedInput) return true;
        return columns.some((col) =>
          item.gridKeyQueryByData[col.name]
            .toLowerCase()
            ?.replace(/[,|.]/g, "")
            .includes(deferedInput.replace(/[,|.]/g, "").toLowerCase())
        );
      }),
    [processedData, deferedInput]
  );

  const sortedAndFilteredData = useMemo(
    () =>
      sort[0] === null || sort[1] === null
        ? filteredData
        : filteredData
            .concat()
            .sort((a: ProcessedDataItem, b: ProcessedDataItem) => {
              const col = columns.find((x) => x.name === sort[0]);
              if (!col) {
                return 0;
              }
              if (
                typeof a.gridKeySortByData[col.name] === "string" &&
                typeof b.gridKeySortByData[col.name] === "string"
              ) {
                return sort[1] === "ASC"
                  ? String(a.gridKeySortByData[col.name]).localeCompare(
                      String(b.gridKeySortByData[col.name])
                    )
                  : String(b.gridKeySortByData[col.name]).localeCompare(
                      String(a.gridKeySortByData[col.name])
                    );
              }
              if (
                a.gridKeySortByData[col.name] < b.gridKeySortByData[col.name]
              ) {
                return sort[1] === "ASC" ? -1 : 1;
              }
              if (
                a.gridKeySortByData[col.name] > b.gridKeySortByData[col.name]
              ) {
                return sort[1] === "ASC" ? 1 : -1;
              }
              return 0;
            }),
    [filteredData, sort]
  );

  return sortedAndFilteredData;
};
