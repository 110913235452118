import { Grid, Tab, Tabs } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

import axiosInstance from "utils/axiosInstance";
import { QueryKeys } from "utils/queryKey";
import CompanyCard from "./CompanyCard";
import CorporateActions from "./CorporateActions";
import FundamentalDetails from "./FundamentalDetails";
import HistoricalPrices from "./HistoricalPrices";
import PricesPage from "./PricesPage";

const CompanyUpdate = () => {
  // const navigate = useNavigate();
  const { id } = useParams();

  const [companyTabs, setCompanyTabs] = useState(0);

  const {
    data: companyDetails,
    isFetching: companyFetching,
    refetch,
  } = useQuery({
    queryKey: [QueryKeys.COMPANY_LIST, id],
    queryFn: async () => axiosInstance.get(`/admin-instrument/${id}/details/`),
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  return (
    <DashboardLayout
      title="Company Details"
      breadcrumbs={[
        {
          name: "Company Master",
          path: "/app/company-master",
        },
        {
          name: "Details",
          path: "",
        },
      ]}
      // toolbar={
      //   <Button onClick={() => navigate(-1)}>
      //     Back
      //     <TbArrowBackUp className="cursor-pointer ml-2 text-xl" />
      //   </Button>
      // }
    >
      <Grid container>
        <Grid item xs={12} mb={1}>
          <CompanyCard companyDetails={companyDetails?.data} />
        </Grid>
        <Grid item xs={12} mb={4}>
          <Tabs
            variant="fullWidth"
            onChange={(e, val) => {
              setCompanyTabs(val);
            }}
            value={companyTabs}
          >
            <Tab label="Fundamental Details" />
            <Tab label="NSE, BSE And ISIN" />
            <Tab label="Historical Prices" />
            <Tab label="Corporate Actions" />
          </Tabs>
        </Grid>

        <Grid item xs={12}>
          {!companyFetching && companyTabs === 0 && (
            <FundamentalDetails
              companyDetails={companyDetails?.data?.data}
              refetch={refetch}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {companyTabs === 1 && <PricesPage />}
        </Grid>
        <Grid item xs={12}>
          {companyTabs === 2 && <HistoricalPrices />}
        </Grid>
        <Grid item xs={12}>
          {companyTabs === 3 && <CorporateActions />}
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default CompanyUpdate;
