import { Outlet } from "react-router-dom";

function App() {
  return (
    <div className="flex h-screen w-screen">
      {/* <Drawer /> */}
      <main className="flex-grow h-full min-w-0">
        <Outlet />
      </main>
    </div>
  );
}

export default App;
