import React from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import TextInput from "./Text";
import { InputProps } from "./types";

type PasswordProps = Omit<InputProps, "appendIcon" | "onAppendClick" | "ref">;

const PasswordInput = React.forwardRef(
  (props: PasswordProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const [isHidden, setIsHidden] = React.useState(true);
    return (
      <TextInput
        ref={ref}
        type={isHidden ? "password" : "text"}
        {...props}
        appendIcon={isHidden ? AiFillEyeInvisible : AiFillEye}
        onAppendClick={() => setIsHidden((val) => !val)}
      />
    );
  }
);

export default PasswordInput;
