import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKModal from "components/GKModal";
import GKTextArea from "components/GKTextarea";
import GKTextField from "components/GKTextField";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  refetch: any;
  templateData: any;
}

const TemplateModal = (props: Props) => {
  const { open, setOpen, refetch, templateData } = props;

  const templateId = templateData?.id;

  const { mutate: handleCreateTemplate } = useMutation({
    mutationKey: ["CREATE_TEMPLATE"],
    mutationFn: (data: any) =>
      axiosInstance.post("/super_admin_whatsapp/create_template/", data),
    onSuccess: (response) => {
      refetch();
      setOpen(false);
      toast.success(response?.data?.message || "Create Template Successfully");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const { mutate: handleUpdateTemplate } = useMutation({
    mutationKey: ["UPDATE_TEMPLATE"],
    mutationFn: (data: any) =>
      axiosInstance.patch(
        `/super_admin_whatsapp/${templateId}/update_template/`,
        data
      ),
    onSuccess: (response) => {
      refetch();
      setOpen(false);
      toast.success(response?.data?.message || "Update Template Successfully");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const { mutate: handleDeleteTemplate } = useMutation({
    mutationKey: ["DELETE_TEMPLATE"],
    mutationFn: () =>
      axiosInstance.delete(
        `/super_admin_whatsapp/${templateId}/delete_template/`
      ),
    onSuccess: (response) => {
      refetch();
      setOpen(false);
      toast.success(response?.data?.message || "Delete Template Successfully");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const formik = useFormik({
    initialValues: {
      templateTitle: templateId ? templateData?.templateTitle : "",
      templateContent: templateId ? templateData?.templateContent : "",
      includeDocument: templateId ? templateData?.includeDocument : false,
      templateName: templateId ? templateData?.templateName : "",
      approvedStatus: templateId ? templateData?.approvedStatus : false,
    },
    onSubmit: async (values) => {
      if (templateId) {
        handleUpdateTemplate(values);
      } else {
        handleCreateTemplate(values);
      }
    },
  });

  const dynamicMessage = `Note: For dynamic value please type {{#}}. Eg. {{advisor_name}}, {{advisor_phone}}, {{advisor_email}}`;

  const insertVariable = (variable: string) => {
    formik.setFieldValue(
      "templateContent",
      `${formik.values.templateContent}{{${variable}}}`
    );
  };

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={templateId ? "Update Template" : "Create Template"}
      footer={
        <Grid container justifyContent={"flex-end"} gap={1}>
          <Button type="submit" form="create-template-form" variant="outlined">
            {templateId ? "Update" : "Save"}
          </Button>
        </Grid>
      }
      titleComponent={
        templateId && (
          <Button
            variant="outlined"
            color="error"
            onClick={() => handleDeleteTemplate()}
          >
            Delete Template
          </Button>
        )
      }
    >
      <form id="create-template-form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="Name"
                name="templateName"
                value={formik.values.templateName}
                onChange={formik?.handleChange}
                disabled={templateId}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="Purpose"
                name="templateTitle"
                value={formik.values.templateTitle}
                onChange={formik?.handleChange}
                disabled={templateData?.approvedStatus}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <GKTextArea
              minRows={4}
              inputLabel="Content"
              name="templateContent"
              placeholder="Content"
              value={formik.values.templateContent}
              onChange={formik?.handleChange}
              disabled={templateData?.approvedStatus}
            />
            <Typography fontSize={12}>{dynamicMessage}</Typography>
          </Grid>
          <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
            <Button
              variant="outlined"
              onClick={() => insertVariable("advisor_name")}
            >
              Insert Advisor Name
            </Button>
            <Button
              variant="outlined"
              onClick={() => insertVariable("advisor_phone")}
            >
              Insert Advisor Phone
            </Button>
            <Button
              variant="outlined"
              onClick={() => insertVariable("advisor_email")}
            >
              Insert Advisor Email
            </Button>
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              style={{
                marginLeft: 2,
              }}
              control={
                <Switch
                  disabled={templateData?.approvedStatus}
                  onChange={(event) => {
                    formik.setFieldValue(
                      "includeDocument",
                      event.target.checked
                    );
                  }}
                  checked={formik.values.includeDocument}
                />
              }
              labelPlacement="end"
              label={
                <b
                  style={{
                    fontSize: 12,
                  }}
                >
                  Include Document
                </b>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              style={{
                marginLeft: 2,
              }}
              control={
                <Switch
                  disabled={templateData?.approvedStatus}
                  onChange={(event) => {
                    formik.setFieldValue(
                      "approvedStatus",
                      event.target.checked
                    );
                  }}
                  checked={formik.values.approvedStatus}
                />
              }
              labelPlacement="end"
              label={
                <b
                  style={{
                    fontSize: 12,
                  }}
                >
                  Approved Status
                </b>
              }
            />
          </Grid>
        </Grid>
      </form>
    </GKModal>
  );
};

export default TemplateModal;
