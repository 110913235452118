/* eslint-disable react/no-array-index-key */
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import React from "react";

interface TabsProps {
  tabs: string[];
  elements: React.ReactNode[];
  className?: string;
  selectedIndex?: number;
  setSelectedIndex?: (index: number) => void;
}
function Tabs({
  tabs,
  elements,
  className,
  selectedIndex,
  setSelectedIndex,
}: TabsProps) {
  return (
    <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
      <Tab.List
        className={classNames(
          "flex space-x-1 rounded-xl p-1 mb-8 customShadowNoHover",
          className
        )}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            className={({ selected }) =>
              classNames(
                "w-full rounded-lg py-2.5 text-sm font-medium leading-5",
                selected
                  ? "bg-primary-dark shadow text-white"
                  : "hover:bg-dark hover:text-default"
              )
            }
          >
            {tab}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels>
        {/* loop around the array and render the component */}
        {elements.map((SingleTab, index) => (
          <Tab.Panel key={index}>{SingleTab}</Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
}

export default Tabs;
