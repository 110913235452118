import classNames from "classnames";
import { VscLoading } from "react-icons/vsc";

interface Props {
  spinnerSize?: string;
}

function SmallSpinner({ spinnerSize }: Props) {
  return (
    <div className="flex items-center mx-2">
      <VscLoading
        className={classNames(
          "animate-spin rounded-full border-b-2 border-primary-dark text-2xl text-white",
          spinnerSize || "w-6 h-6"
        )}
      />
    </div>
  );
}

export default SmallSpinner;
