import axios from "axios";
import { getUsetType, token } from "./common";

const userInfo = JSON.parse(localStorage?.getItem("userInfo"));

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 60000,
  headers: {
    Authorization: `Token ${JSON.parse(token)}`,
    "x-gridkey-user-role": getUsetType(userInfo),
  },
});

export default axiosInstance;
