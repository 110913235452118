export const data = [
  {
    id: 1,
    portfolio: "Portfolio 1",
    feePlan: "Fee Plan 1",
    partners: "Partner 1",
    revenueCollected: 100000,
    unbilledRevenue: 100000,
    lastBillingDate: "2021-01-01",
    approved: "YES",
  },
  {
    id: 2,
    portfolio: "Portfolio 2",
    feePlan: "Fee Plan 2",
    partners: "Partner 2",
    revenueCollected: 200000,
    unbilledRevenue: 200000,
    lastBillingDate: "2021-01-01",
    approved: "YES",
  },
  {
    id: 3,
    portfolio: "Portfolio 3",
    feePlan: "Fee Plan 3",
    partners: "Partner 3",
    revenueCollected: 300000,
    unbilledRevenue: 300000,
    lastBillingDate: "2021-01-01",
    approved: "YES",
  },
  {
    id: 4,
    portfolio: "Portfolio 4",
    feePlan: "Fee Plan 4",
    partners: "Partner 4",
    revenueCollected: 400000,
    unbilledRevenue: 400000,
    lastBillingDate: "2021-01-01",
    approved: "YES",
  },
  {
    id: 5,
    portfolio: "Portfolio 5",
    feePlan: "Fee Plan 5",
    partners: "Partner 5",
    revenueCollected: 500000,
    unbilledRevenue: 500000,
    lastBillingDate: "2021-01-01",
    approved: "YES",
  },

  {
    id: 6,
    portfolio: "Portfolio 6",
    feePlan: "Fee Plan 6",
    partners: "Partner 6",
    revenueCollected: 600000,
    unbilledRevenue: 600000,
    lastBillingDate: "2021-01-01",
    approved: "YES",
  },
];
