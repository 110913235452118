import { LoginResponse } from "interfaces/Authentication";
import { Permissions } from "interfaces/Permissions";
import { ADVISOR, INVESTOR, TOKEN, USERINFO } from "utils/auth";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resetStates } from "../store/revertStates";

interface UserInfo {
  companyId: number;
  hasPassword: boolean;
  name: string;
  phone: string;
  permissions: Permissions;
  companyAccess: boolean;
}
interface AuthenticationState {
  isAuthenticated: boolean | string;
  isInvestor: boolean;
  isAdvisor: boolean;
  userInfo: UserInfo | null;
  isCompanyCreateModalOpen?: boolean;
}

const initialState: AuthenticationState = {
  isAuthenticated: !!JSON.parse(TOKEN?.startsWith('"') ? "true" : "false"),
  isInvestor: !!JSON.parse(INVESTOR || "false"),
  isAdvisor: !!JSON.parse(ADVISOR || "false"),
  userInfo: JSON.parse(USERINFO || "null"),
};

const setIsInvestorActive = (state: AuthenticationState) => {
  state.isInvestor = true;
  state.isAdvisor = false;
  localStorage.setItem(`isInvestor`, JSON.stringify(state.isInvestor));
};
const setIsAdvisorActive = (state: AuthenticationState) => {
  state.isAdvisor = true;
  state.isInvestor = false;
  localStorage.removeItem(`isInvestor`);
};

const Login = (
  state: AuthenticationState,
  action: PayloadAction<LoginResponse>
) => {
  if (!state.isAuthenticated) {
    localStorage.setItem(`token`, JSON.stringify(action.payload.token));
  }
  if (action.payload.isEmployee) {
    localStorage.setItem(
      `isAdvisor`,
      JSON.stringify(action.payload.isEmployee)
    );
    state.isInvestor = false;
    state.isAdvisor = true;
  } else {
    localStorage.setItem(
      `isInvestor`,
      JSON.stringify(action.payload.isEmployee)
    );
    state.isInvestor = true;
    state.isAdvisor = false;
  }
  localStorage.setItem(
    `userInfo`,
    JSON.stringify({
      companyId: action.payload.companyId,
      hasPassword: action.payload.hasPassword,
      name: action.payload.name,
      phone: action.payload.phone,
      permissions: action.payload.permissions,
      companyAccess: action.payload.companyAccess,
    })
  );
  state.userInfo = {
    companyId: action.payload.companyId,
    hasPassword: action.payload.hasPassword,
    name: action.payload.name,
    phone: action.payload.phone,
    permissions: action.payload.permissions,
    companyAccess: action.payload.companyAccess,
  };
  state.isAuthenticated = action?.payload?.token;
};

const Logout = (state: AuthenticationState) => {
  localStorage.removeItem(`token`);
  localStorage.removeItem(`isInvestor`);
  localStorage.removeItem(`isAdvisor`);
  localStorage.removeItem(`userInfo`);
  localStorage.removeItem(`version`);
  state.isAuthenticated = false;
  state.isInvestor = false;
  state.isAdvisor = false;
  state.userInfo = null;
};

const openCompanyCreateModal = (state: AuthenticationState) => {
  state.isCompanyCreateModalOpen = true;
};

const closeCompanyCreateModal = (state: AuthenticationState) => {
  state.isCompanyCreateModalOpen = false;
};

const AuthenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    Login,
    Logout,
    setIsInvestorActive,
    setIsAdvisorActive,
    openCompanyCreateModal,
    closeCompanyCreateModal,
  },
  extraReducers: (builder) => builder.addCase(resetStates, () => initialState),
});

export const AuthenticationActions = AuthenticationSlice.actions;
export default AuthenticationSlice.reducer;
