import { InputLabel, TextField, TextFieldProps, styled } from "@mui/material";

interface GKTextFieldProps {
  inputLabel?: string;
}

const BootstrapInput = styled(TextField)(({ theme }) => ({
  "label + &": {
    marginTop: "20px",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid",
    borderColor: "#006580",
  },
  "& .MuiInputBase-input": {
    borderRadius: "8px",
    position: "relative",
    fontSize: 12,
    padding: "12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
  },
  "& .MuiFormHelperText-root ": {
    margin: 0,
    color: theme.palette.error.main,
    fontWeight: 500,
  },
}));

function GKTextField(props: GKTextFieldProps & TextFieldProps) {
  const { inputLabel, id, name, placeholder } = props;
  return (
    <>
      {inputLabel && (
        <InputLabel
          style={{
            color: "#01657F",
            fontSize: "18px",
            textTransform: "capitalize",
          }}
          shrink
          htmlFor={name || id}
        >
          {inputLabel}
        </InputLabel>
      )}
      <BootstrapInput
        {...props}
        autoComplete="off"
        fullWidth
        placeholder={inputLabel || placeholder || ""}
      />
    </>
  );
}

export default GKTextField;
