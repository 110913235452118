import { Button, FormControl, Grid } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKModal from "components/GKModal";
import GKTextField from "components/GKTextField";
import SmallSpinner from "components/Spinners/SmallSpinner";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { object, string } from "yup";

const CreateAdvisorModal = ({ setOpen, open, refetch }: any) => {
  const advisorSchema = object({
    phone: string()
      .required("Please enter Phone Number")
      .matches(/^[0-9]{10}$/, "Phone number should be 10 digits"),
  });

  const { mutate: createAdvisor, isLoading } = useMutation({
    mutationKey: ["CREATE_ADVISOR"],
    mutationFn: (values: any) =>
      axiosInstance.post(`/super_admin/create_advisor/`, values),
    onSuccess: (response) => {
      refetch();
      setOpen(false);
      toast.success(response?.data?.message || "Create Advisor Successfully");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: advisorSchema,
    onSubmit: (values: any) => {
      const data = {
        phone: values.phone.toString(),
      };

      createAdvisor(data);
    },
  });

  return (
    <GKModal
      modalTitle="Create Advisor"
      open={open}
      setOpen={setOpen}
      footer={
        <>
          <Button
            variant="outlined"
            size="medium"
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
          <Button
            variant="contained"
            type="submit"
            size="medium"
            form="add-advisor-form"
          >
            {isLoading ? <SmallSpinner /> : "Create Advisor"}
          </Button>
        </>
      }
    >
      <form id={"add-advisor-form"} onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="Phone"
                type="number"
                name={"phone"}
                value={formik.values.phone}
                onChange={formik?.handleChange}
                error={
                  Boolean(formik.errors.phone) && Boolean(formik.touched.phone)
                }
                helperText={
                  Boolean(formik.touched.phone) &&
                  Boolean(formik.errors.phone) &&
                  `${formik.errors.phone}`
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </GKModal>
  );
};

export default CreateAdvisorModal;
