import { ThemeProvider } from "@emotion/react";
import { IconButton } from "@mui/material";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { AppContextProvider } from "AppContext";
import AdminAccessBoard from "Pages/AdminAccessBoard";
import Authentication from "Pages/Authentication";
import Test from "Pages/Cards/Faqs";
import Communication from "Pages/Communication";
import CompanyMasterMainPage from "Pages/CompanyMaster";
import CompanyUpdate from "Pages/CompanyMaster/Components/UpdateCompany";
import UniqueCompany from "Pages/CompanyMaster/UniqueCompany";
import Dashboard from "Pages/Dashboard";
import AdvisorDetails from "Pages/Dashboard/AdvisorDetails";
import UserDetails from "Pages/Dashboard/UserDetails";
import Preference from "Pages/Preference";
import FeeStructure from "Pages/RevenueManagement/FeeStructure";
import AddFeePlanSetup from "Pages/RevenueManagement/FeeStructure/Components/FeePlanSetup/AddPlan";
import PrivateRoute from "components/PrivateRoute";
import { useAppSelector } from "hooks/useToolkit";
import toast, { ToastBar, Toaster } from "react-hot-toast";
import { BiSolidErrorAlt } from "react-icons/bi";
import { HiBadgeCheck } from "react-icons/hi";
import { MdClose } from "react-icons/md";
import { Navigate, Route, Routes } from "react-router-dom";
import { RootState } from "services/redux/store";
import { theme } from "utils/Theme";
import App from "./Pages";
import BrokerMaster from "./Pages/BrokerMaster";
import BrokerMasterDetails from "./Pages/BrokerMaster/Components/DetailsPage";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE);

function Main() {
  const { isAuthenticated } = useAppSelector(
    (state: RootState) => state.AuthenticationReducer
  );

  return (
    <>
      <AppContextProvider>
        <ThemeProvider theme={theme}>
          <Routes>
            {/* login routes */}
            {!isAuthenticated ? (
              <Route path="/" element={<Authentication />} />
            ) : (
              <Route
                path="*"
                element={<Navigate to="/app/dashboard" replace />}
              />
            )}
            <Route path="/app" element={<PrivateRoute />}>
              <Route path="/app" element={<App />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route
                  path="admin-access-board"
                  element={<AdminAccessBoard />}
                />
                {/* company master */}
                <Route
                  path="company-master"
                  element={<CompanyMasterMainPage />}
                />
                <Route path="broker-master" element={<BrokerMaster />} />
                <Route
                  path="broker-master/:id"
                  element={<BrokerMasterDetails />}
                />
                <Route
                  path="company-master/update/:id"
                  element={<CompanyUpdate />}
                />
                {/* Revenue Management */}
                <Route
                  path="revenue-management/fee-structure"
                  element={<FeeStructure />}
                />
                <Route
                  path="revenue-management/fee-structure/add-fee-plan"
                  element={<AddFeePlanSetup />}
                />
                <Route
                  path="advisor/:id/details"
                  element={<AdvisorDetails />}
                />
                <Route
                  path="dashboard/user-details"
                  element={<UserDetails />}
                />
                <Route path="preference/:id" element={<Preference />} />
                <Route
                  path="company-master/unique-company"
                  element={<UniqueCompany />}
                />
                <Route path="card" element={<Test />} />
                <Route path="communication" element={<Communication />} />
                {isAuthenticated ? (
                  <Route
                    path="*"
                    element={<Navigate to="/app/dashboard" replace />}
                  />
                ) : (
                  <Route path="*" element={<Navigate to="/" replace />} />
                )}
              </Route>
            </Route>
          </Routes>
        </ThemeProvider>
      </AppContextProvider>

      <Toaster
        position="bottom-right"
        reverseOrder={false}
        gutter={8}
        toastOptions={{
          duration: 6000,
          style: {
            boxShadow: `0 0 10px 2px ${theme.palette.grey[600]}`,
            fontWeight: 700,
            padding: "10px 10px",
            fontFamily: "Inter",
            maxWidth: "unset",
          },
          success: {
            icon: <HiBadgeCheck size={26} color={theme.palette.success.main} />,
          },
          error: {
            icon: (
              <BiSolidErrorAlt size={26} color={theme.palette.error.main} />
            ),
          },
        }}
      >
        {(t) => (
          <ToastBar toast={t} position="bottom-right">
            {({ icon, message }) => (
              <>
                {icon}
                {message}
                {t.type !== "loading" && (
                  <IconButton
                    style={{
                      padding: 4,
                    }}
                    onClick={() => toast.dismiss(t.id)}
                  >
                    <MdClose size={16} />
                  </IconButton>
                )}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
    </>
  );
}

export default Main;
