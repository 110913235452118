import classNames from "classnames";
import React, { useId } from "react";
import { useValidation } from "../../hooks/form";
import { InputProps } from "./types";

const classMap = {
  filled: "bg-light",
  outlined: "bg-light border border-default",
  HoldingVarient: "bg-white customShadowNoHover",
};

const Input = React.forwardRef(
  (props: InputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const {
      value,
      onChange: onChangeProp,
      rules = [],
      label,
      placeholder = "",
      prependIcon: PrependIcon,
      onPrependClick,
      appendIcon: AppendIcon,
      onAppendClick,
      type = "text",
      className,
      customForHoldings,
      variant = `filled`,
      noError = false,
      val,
      rounded = false,
      disabled = false,
      ...otherProps
    } = props;

    const { error } = useValidation(rules, value);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeProp(event.target.value);
    };
    const id = useId();

    const data = val !== undefined ? val : value;

    return (
      <div className={classNames(className, "group mb-2")}>
        <label
          htmlFor={id}
          className="text-sm text-light font-semibold mb-1 group-focus-within:text-primary-dark block"
        >
          {label}
        </label>
        <div
          className={classNames(
            "px-3 p-3 rounded-lg min-w-0 w-full flex items-center",
            error
              ? "ring-error ring-2"
              : "focus-within:ring-2 focus-within:ring-primary-dark",
            classMap[variant],
            rounded && "rounded-full",
            disabled && "bg-secondary-light cursor-not-allowed"
          )}
        >
          {PrependIcon && (
            <PrependIcon
              className="text-light mr-2 text-xl flex-shrink-0"
              onClick={onPrependClick}
            />
          )}
          <input
            ref={ref}
            id={id}
            className={classNames(
              "outline-none bg-transparent flex-grow disabled:cursor-not-allowed"
            )}
            value={data}
            onChange={onChange}
            placeholder={placeholder}
            type={type}
            disabled={disabled}
            {...otherProps}
          />
          {AppendIcon && (
            <AppendIcon
              className={classNames(
                "text-light ml-2 text-xl flex-shrink-0",
                type === "password" && "cursor-pointer"
              )}
              onClick={onAppendClick}
            />
          )}
        </div>
        {!noError && (
          <span className="block h-[1em] text-error text-sm">{error}</span>
        )}
      </div>
    );
  }
);

export default Input;
