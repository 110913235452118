/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unused-prop-types */
import { Card, Grid, IconButton, Typography } from "@mui/material";
import classNames from "classnames";
import { ReactComponent as SpinnerOverlay } from "components/Spinners/spinneroverlay.svg";
import React, { useState } from "react";
import { MdCloudUpload, MdOutlineDelete } from "react-icons/md";
import Lottie from "react-lottie";
import { theme } from "utils/Theme";
import animationData from "./lf30_editor_5hqa1nel.json";

interface DragDropProps {
  value: File[];
  onChange: (files: File[]) => void;
  isLoading?: boolean;
  supportName?: string;
}

function DragDrop(props: DragDropProps) {
  const { onChange, isLoading = false, value, supportName } = props;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      onChange(Array.from(event.dataTransfer.files));
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.target.files && event.target.files.length > 0) {
      onChange(Array.from(event.target.files));
    }
    event.target.value = "";
  };

  const handleDelete = (index: number) => {
    const newValue = value?.filter((data, i) => i !== index);
    onChange(newValue);
  };

  return value?.length === 0 ? (
    <label
      className={classNames(
        "min-h-[100px] block bg-primary-dark/20 rounded-xl h-0 cursor-pointer py-[80px] border-primary-dark"
      )}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <div className="pointer-events-none h-full w-full rounded-xl flex items-center justify-center relative">
        <span className={classNames("text-light", isLoading && "opacity-50")}>
          {isDragging ? (
            <div className="flex flex-col mb-[40px]">
              <div className="w-[150px] h-[150px] ml-[20px]">
                <Lottie options={defaultOptions} width="100%" height="100%" />
              </div>
              <div className=" flex items-center justify-center flex-col">
                <span className="">Please drop your files here</span>
                <span className="block">
                  to <span className="font-semibold">upload</span>
                </span>
              </div>
            </div>
          ) : (
            <Grid container>
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <MdCloudUpload color={theme.palette.primary.main} size={70} />
              </Grid>
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography fontSize={12} textAlign={"center"} fontWeight={600}>
                  Click to select the file or Drag & Drop your file here to
                  import data.
                  <Typography
                    fontSize={12}
                    display={"flex"}
                    justifyContent={"center"}
                    fontWeight={600}
                  >
                    (Supported formats :
                    <Typography
                      fontSize={12}
                      fontWeight={600}
                      color={theme.palette.primary.main}
                    >
                      &nbsp;{supportName || "CSV"}
                    </Typography>
                    )
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          )}
        </span>
        {isLoading && <SpinnerOverlay />}
      </div>
      <input type="file" className="hidden" onChange={handleChange} />
    </label>
  ) : (
    <Grid container>
      <Card style={{ width: "100%" }}>
        {value?.map((dataItem, index) => (
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography>{dataItem.name}</Typography>
            <IconButton
              color="error"
              onClick={() => {
                handleDelete(index);
              }}
            >
              <MdOutlineDelete />
            </IconButton>
          </Grid>
        ))}
      </Card>
    </Grid>
  );
}

export default DragDrop;
