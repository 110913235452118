import { CircularProgress, Typography, styled } from "@mui/material";
import { theme } from "utils/Theme";

const StyledGridOverlay = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "calc(100vh - 310px)",
}));

export const CustomLoader = () => {
  return (
    <StyledGridOverlay>
      <CircularProgress />
      <Typography
        marginTop={1}
        fontWeight={600}
        fontSize={14}
        width={"60%"}
        textAlign={"center"}
        color={theme.palette.dark.main}
      >
        Hold on tight, we are fetching data.
      </Typography>
    </StyledGridOverlay>
  );
};

export const Pagination = () => {
  return <div> </div>;
};

export const EmptyData = () => {
  return (
    <StyledGridOverlay>
      {/* <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg> */}
      <svg
        width="200"
        height="200"
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 650 512"
        id="empty-box"
      >
        <path
          fill="#dbe8ec"
          d="M568.90415,220.74547V202.55431a13.07945,13.07945,0,0,0-13.07945-13.07945h-46.166a13.07945,13.07945,0,0,1-13.07946-13.07945V158.20424a13.07945,13.07945,0,0,1,13.07946-13.07945h1.45764a13.07945,13.07945,0,0,0,13.07945-13.07945V113.85418a13.07945,13.07945,0,0,0-13.07945-13.07945H169.20918a13.07945,13.07945,0,0,0-13.07945,13.07945v18.19116a13.07945,13.07945,0,0,0,13.07945,13.07945h0a13.07945,13.07945,0,0,1,13.07945,13.07945v18.19117a13.07945,13.07945,0,0,1-13.07945,13.07945H118.6298a13.07945,13.07945,0,0,0-13.07945,13.07945v18.19116a13.07945,13.07945,0,0,0,13.07945,13.07945h19.84658a13.07945,13.07945,0,0,1,13.07945,13.07946v18.19124a13.07945,13.07945,0,0,1-13.07945,13.07946h-.048A13.07945,13.07945,0,0,0,125.349,291.25453V309.4456a13.07945,13.07945,0,0,0,13.07945,13.07946h2.87139a13.07945,13.07945,0,0,1,13.07945,13.07945v18.19125a13.07945,13.07945,0,0,1-13.07945,13.07945H94.1753a13.07945,13.07945,0,0,0-13.07945,13.07945v18.19116A13.07945,13.07945,0,0,0,94.1753,411.22527h423.963a13.07945,13.07945,0,0,0,13.07945-13.07945V379.95466a13.07945,13.07945,0,0,0-13.07945-13.07945H506.79779a13.07945,13.07945,0,0,1-13.07945-13.07945V335.60451a13.07945,13.07945,0,0,1,13.07945-13.07945h26.73986A13.07945,13.07945,0,0,0,546.6171,309.4456V291.25453a13.07945,13.07945,0,0,0-13.07945-13.07945H525.426a13.07945,13.07945,0,0,1-13.07945-13.07946V246.90438A13.07945,13.07945,0,0,1,525.426,233.82492H555.8247A13.07945,13.07945,0,0,0,568.90415,220.74547Z"
        />
        <rect
          width="159.84"
          height="145.44"
          x="217.561"
          y="231.797"
          fill="#01657F"
        />
        <rect
          width="84.96"
          height="145.44"
          x="377.401"
          y="231.797"
          fill="#01657F"
        />
        <path
          fill="#01657F"
          d="M462.36121,232.15729h-84.96l29.101-39.69379a3,3,0,0,1,2.41944-1.22621h77.52026a3,3,0,0,1,2.41944,4.77378Z"
        />
        <path
          fill="#01657F"
          d="M217.95637,232.15729h84.96l-29.101-39.69379a3,3,0,0,0-2.41944-1.22621H193.87565a3,3,0,0,0-2.41944,4.77378Z"
        />
        <path
          fill="#01657F"
          d="M510.29292,285.1577l-47.93164-53.00043h0v57.96h45.66016A3,3,0,0,0,510.29292,285.1577Z"
        />
        <rect
          width="110.853"
          height="78.366"
          x="246.893"
          y="153.791"
          fill="#ee781d"
        />
        <path
          fill="#dbe8ec"
          d="M299.07973,236.99513h-30.498a3.35392,3.35392,0,0,1-2.90457-5.03088l15.249-26.41208,15.249-26.41208a3.35392,3.35392,0,0,1,5.80915,0l15.249,26.41208,15.249,26.41208a3.35392,3.35392,0,0,1-2.90457,5.03088Z"
        />
        <rect
          width="3.356"
          height="28.368"
          x="297.402"
          y="192.301"
          fill="#409cb5"
          rx="1.678"
        />
        <circle
          cx="299.08"
          cy="227.235"
          r="4.014"
          fill="#409cb5"
          transform="rotate(-84.345 299.08 227.235)"
        />
        <path
          fill="#47acc4"
          d="M325.66717,290.11729H173.75124a3,3,0,0,1-2.27157-4.9596l45.72154-53.0004h159.84l-49.10248,56.91959A3,3,0,0,1,325.66717,290.11729Z"
        />
        <path
          fill="none"
          stroke="#b9d4db"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="3"
          d="M474.287 338.33442c3.78968 0 3.78968 2 7.57935 2 3.78847 0 3.78847-2 7.57694-2 3.79107 0 3.79107 2 7.58215 2s3.79107-2 7.58215-2M474.287 347.07361c3.78968 0 3.78968 2 7.57935 2 3.78847 0 3.78847-2 7.57694-2 3.79107 0 3.79107 2 7.58215 2s3.79107-2 7.58215-2M474.287 355.8128c3.78968 0 3.78968 2 7.57935 2 3.78847 0 3.78847-2 7.57694-2 3.79107 0 3.79107 2 7.58215 2s3.79107-2 7.58215-2M111.18347 292.11729c3.78967 0 3.78967 2 7.57934 2 3.78847 0 3.78847-2 7.57694-2 3.79108 0 3.79108 2 7.58215 2s3.79108-2 7.58215-2M111.18347 300.85648c3.78967 0 3.78967 2 7.57934 2 3.78847 0 3.78847-2 7.57694-2 3.79108 0 3.79108 2 7.58215 2s3.79108-2 7.58215-2M111.18347 309.59568c3.78967 0 3.78967 2 7.57934 2 3.78847 0 3.78847-2 7.57694-2 3.79108 0 3.79108 2 7.58215 2s3.79108-2 7.58215-2M279.90529 120.54318c3.78967 0 3.78967 2 7.57934 2 3.78847 0 3.78847-2 7.57694-2 3.79108 0 3.79108 2 7.58215 2s3.79108-2 7.58215-2M279.90529 129.28237c3.78967 0 3.78967 2 7.57934 2 3.78847 0 3.78847-2 7.57694-2 3.79108 0 3.79108 2 7.58215 2s3.79108-2 7.58215-2M279.90529 138.02156c3.78967 0 3.78967 2 7.57934 2 3.78847 0 3.78847-2 7.57694-2 3.79108 0 3.79108 2 7.58215 2s3.79108-2 7.58215-2"
        />
        <circle cx="183.705" cy="391.21" r="12.96" fill="#b9d4db" />
        <circle cx="193.109" cy="357.024" r="6.72" fill="#b9d4db" />
        <circle cx="219.723" cy="392.65" r="9.6" fill="#b9d4db" />
        <circle cx="170.969" cy="212.701" r="15.42" fill="#b9d4db" />
        <circle cx="141.504" cy="201.718" r="8.032" fill="#b9d4db" />
        <circle cx="148.882" cy="174.415" r="4.633" fill="#b9d4db" />
        <circle cx="174.529" cy="177.693" r="11.107" fill="#b9d4db" />
        <circle cx="438.988" cy="137.917" r="15.42" fill="#b9d4db" />
        <circle cx="463.523" cy="118.249" r="8.032" fill="#b9d4db" />
        <circle cx="494.205" cy="162.323" r="6.952" fill="#b9d4db" />
        <circle cx="483.183" cy="138.58" r="4.633" fill="#b9d4db" />
        <circle cx="467.22" cy="158.92" r="11.107" fill="#b9d4db" />
        <text
          x="50%"
          y="95%"
          textAnchor="middle"
          fill="#000"
          fontSize="45px"
          fontFamily="Manrope"
          fontWeight="600"
        >
          No Data Found.
        </text>
      </svg>
    </StyledGridOverlay>
  );
};
