import Tabs from "components/Tabs";
import { useContext } from "react";
import BillGeneration from "../BillGeneration";
import FeeStructureContext from "../Context";
import FeePlanSetup from "../FeePlanSetup";
import FeeStructure from "../FeeStructure";
import RevenueSharing from "../RevenueSharing";

function MainTabs() {
  const { selectedIndex, setSelectedIndex } = useContext(FeeStructureContext);
  const tabs = [
    "Bill Generation",
    "Fee Structure",
    "Revenue Sharing",
    "Fee Plan Setup",
  ];
  const elements = [
    <BillGeneration />,
    <FeeStructure />,
    <RevenueSharing />,
    <FeePlanSetup />,
  ];

  return (
    <Tabs
      selectedIndex={selectedIndex}
      setSelectedIndex={setSelectedIndex}
      tabs={tabs}
      elements={elements}
    />
  );
}

export default MainTabs;
