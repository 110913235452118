import { theme } from "utils/Theme";

function StatusField({ statusString }: any) {
  const returnStatusColor = (text: string) => {
    switch (text && text?.toLowerCase()) {
      case "active":
        return theme.palette.success.main;
      case "success":
        return theme.palette.success.main;
      case "finished":
        return theme.palette.success.main;
      case "completed":
        return theme.palette.success.main;
      case "mapped":
        return theme.palette.success.main;
      case "inactive":
        return theme.palette.error.main;
      case "unmapped":
        return theme.palette.error.main;
      case "low":
        return theme.palette.error.main;
      case "moderate":
        return theme.palette.info.main;
      case "high":
        return theme.palette.success.main;
      case "pending":
        return theme.palette.warning.main;
      case "approved":
        return theme.palette.info.main;
      case "rejected":
        return theme.palette.error.main;
      case "true":
        return theme.palette.success.main;
      case "false":
        return theme.palette.error.main;
      default:
        return `#5A5A5A`;
    }
  };

  return (
    <div
      style={{
        minWidth: "100px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "8px",
        padding: "6px 10px",
        fontWeight: 500,
        fontSize: 13,
        background: `${returnStatusColor(statusString)}22`,
        color: returnStatusColor(statusString),
        textTransform: "capitalize",
      }}
    >
      {statusString}
    </div>
  );
}

export default StatusField;
