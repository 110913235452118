import Button from "components/Button";
import DashboardLayout from "layouts/DashboardLayout";
import { useContext } from "react";
import { MdAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import FeeStructureContext from "../Context";
import MainTabs from "../Tabs";

function MainPage() {
  const navigate = useNavigate();
  const { selectedIndex } = useContext(FeeStructureContext);

  const handleButtonText = (index: number) => {
    if (index === 3) return "Add Plan Setup";
    return "Add";
  };

  const handleButtonClick = (index: number) => {
    if (index === 3)
      navigate("/app/revenue-management/fee-structure/add-fee-plan");
  };

  return (
    <DashboardLayout
      title="Fee Structure"
      breadcrumbs={[
        {
          name: "Revenue Management",
          path: "",
        },
        {
          name: "Fee Structure",
          path: "",
        },
      ]}
      toolbar={
        <div className="flex gap-2">
          {selectedIndex === 3 && (
            <Button
              className="px-3 py-2 flex"
              color="primary"
              onClick={() => handleButtonClick(selectedIndex)}
            >
              <MdAdd className="mr-1 text-2xl" />
              {handleButtonText(selectedIndex)}
            </Button>
          )}
        </div>
      }
    >
      <div className="bg-white p-5 rounded-md">
        <MainTabs />
      </div>
    </DashboardLayout>
  );
}

export default MainPage;
