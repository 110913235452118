import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import SimpleLoader from "components/SimpleLoader";
import { useAppSelector } from "hooks/useToolkit";
import { Navigate, Outlet } from "react-router-dom";
import { RootState } from "services/redux/store";
import axiosInstance from "utils/axiosInstance";

function PrivateRoute() {
  const { isAuthenticated } = useAppSelector(
    (state: RootState) => state.AuthenticationReducer
  );

  const { dispatch } = useAppContext();

  const { isFetching } = useQuery({
    queryKey: ["CURRENT_USER"],
    queryFn: () => axiosInstance.get("/user-details/"),
    onSuccess: (response) => {
      dispatch({ type: "CURRENT_USER", data: response?.data?.data });
    },
  });

  if (isFetching) return <SimpleLoader />;

  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
}

export default PrivateRoute;
