interface HeadingTypes {
  heading: string;
  subHeading: string;
  border?: boolean;
  className?: string;
}
function Heading({ heading, subHeading, border, className }: HeadingTypes) {
  return (
    <div
      className={`w-full pb-[5px] ${className} ${
        border === true ? `border-b-2 border-light` : ``
      }`}
    >
      <h4 className="text-lg font-semibold normal-case text-primary-dark">
        {heading}
      </h4>
      <p className="normal-case ">{subHeading}</p>
    </div>
  );
}

export default Heading;
