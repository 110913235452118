import classNames from "classnames";
import React from "react";

const colorMap = {
  primary:
    "text-primary-dark hover:bg-primary-dark border-[1px] border-solid border-primary-dark bg-white text-[15px] hover:text-white transition-all duration-300",
  secondary: "bg-secondary-default hover:bg-secondary-dark text-white",
  transparent: "hover:bg-light text-light hover:text-default",
  error: "bg-error hover:bg-error/80 text-white",
};

interface ButtonProps extends React.ComponentProps<"button"> {
  icon?: boolean;
  children: React.ReactNode;
  color?: "primary" | "secondary" | "transparent" | "error";
  rounded?: boolean;
}

function Button(props: ButtonProps) {
  const {
    children,
    color = "primary",
    rounded = false,
    className,
    ...otherProps
  } = props;
  return (
    <button
      type="button"
      {...otherProps}
      className={classNames(
        className,
        "disabled:shadow-none hover:shadow-lg hover:bg-primary-dark active:shadow-none active:translate-y-[2px] transform-gpu rounded-lg transition-all flex items-center disabled:opacity-80 disabled:cursor-not-allowed disabled:text-primary-dark disabled:hover:bg-white disabled:bg-white",
        colorMap[color],
        rounded ? "rounded-full" : "rounded-lg"
      )}
    >
      {children}
    </button>
  );
}

export default Button;
