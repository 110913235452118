/* eslint-disable react/no-danger */
import { Button, Grid, IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMutation, useQuery } from "@tanstack/react-query";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import GKClientDataGrid from "components/GKClientDataGrid";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import toast from "react-hot-toast";
import { MdDelete } from "react-icons/md";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import CreateCard from "./CreateCard";
import CreateModule from "./CreateModule";

const Faq = () => {
  const [openCreateCard, setOpenCreateCard] = useState(false);

  const [openCreateModule, setOpenCreateModule] = useState(false);

  const [cardData, setCardData] = useState({});

  const [moduleData, setModuleData] = useState({});

  const [confirmationModal, setConfirmationModal] = useState(false);

  const [brokerTempleId, setBrokerTempleId] = useState<any>(0);

  const { data, isLoading, refetch } = useQuery({
    queryKey: [QueryKeys.BROKER_MASTER_DETAILS_LIST],
    queryFn: () => axiosInstance.get(`/faq/`),
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const { mutate: handleDeleteCardDetails } = useMutation({
    mutationKey: ["DELETE_FAQ"],
    mutationFn: () => axiosInstance.delete(`/faq/${brokerTempleId}/`),
    onSuccess: (response) => {
      refetch();
      toast.success(
        response?.data?.message || "Delete FAQ Details Successfully"
      );
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Description", "description", 100, 1),
      renderCell: (params) => (
        <div
          dangerouslySetInnerHTML={{
            __html: params.row.description,
          }}
        />
      ),
    },

    // {
    //   headerName: "mediaFile",
    //   field: "mediaFile",
    //   flex: 1,
    //   minWidth: 100,
    //   disableColumnMenu: true,
    // },
    {
      ...extraColumnsField("ModuleName", "moduleName", 100, 1),
    },
    {
      ...extraColumnsField("Question", "question", 100, 1),
    },
    {
      ...extraColumnsField("Actions", "action", 80, 1),
      headerAlign: "right",
      align: "right",
      type: "action",
      renderCell: (item) => (
        <Grid container gap={1} justifyContent={"flex-end"}>
          <IconButton
            color="error"
            onClick={(event) => {
              event.stopPropagation();
              setBrokerTempleId(item.id);
              setCardData({});
              setConfirmationModal(true);
            }}
          >
            <MdDelete size={20} />
          </IconButton>
        </Grid>
      ),
    },
  ];

  return (
    <DashboardLayout
      title="FAQ"
      breadcrumbs={[
        {
          name: "FAQ",
          path: "/app/Card",
        },
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <GKClientDataGrid
            headerComponent={
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"flex-end"}
                gap={1}
              >
                <Button
                  variant="contained"
                  // size="small"
                  onClick={() => {
                    setModuleData({});
                    setOpenCreateModule(true);
                  }}
                >
                  Create Module
                </Button>
                <Button
                  variant="contained"
                  // size="small"
                  onClick={() => {
                    setCardData({});
                    setOpenCreateCard(true);
                  }}
                >
                  Create FAQ
                </Button>
              </Grid>
            }
            loading={isLoading}
            columns={columns}
            rows={data?.data?.data || []}
            onRowClick={(row) => {
              setCardData(row.row);
              setOpenCreateCard(true);
            }}
          />
        </Grid>
      </Grid>
      {openCreateCard && (
        <CreateCard
          inCardOpen={openCreateCard}
          setIsCardOpen={setOpenCreateCard}
          refetch={refetch}
          cardData={cardData}
        />
      )}
      {confirmationModal && (
        <ConfirmDeleteModal
          open={confirmationModal}
          setOpen={setConfirmationModal}
          onDelete={() => handleDeleteCardDetails()}
          title={"Faq"}
          isDelete
        />
      )}
      {openCreateModule && (
        <CreateModule
          inModuleOpen={openCreateModule}
          setIsModuleOpen={setOpenCreateModule}
          refetch={refetch}
          moduleData={moduleData}
        />
      )}
    </DashboardLayout>
  );
};

export default Faq;
