export const moneyCommaSeparator = (value: number) =>
  value === Infinity || value === -Infinity || Number.isNaN(value)
    ? "0.00"
    : value?.toLocaleString("en-IN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

export const moneyCommaSeparatorPoint = (value: number) =>
  value === Infinity || value === -Infinity || Number.isNaN(value)
    ? "0.00"
    : value?.toLocaleString("en-IN", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });

export const numberToCurrency = (value: number) =>
  value?.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
  });

export const exponentialToDecimal = (value: number) => {
  if (value.toString().includes("e")) {
    return Number(value).toFixed(2);
  }
  return String(value);
};

export const handleInfinityOrNan = (value: number) => {
  if (value === Infinity || value === -Infinity || Number.isNaN(value)) {
    return 0;
  }
  return value;
};

export const formatDate = (date: string) => {
  if (date) {
    return date.replace(/-/g, "/");
  }
  return date;
};

export const errorHandler = (error: any) => {
  if (error.status === 401) return "Please login again, session expired";
  return error.errors.message || "Something went wrong, please try again later";
};

export const copyTextToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (err) {
    return false;
  }
};

export const extraColumnsField = (
  headerName: string,
  field: string,
  minWidth?: number,
  flex?: number
) => {
  return {
    disableColumnMenu: true,
    disableExport: true,
    headerName: headerName,
    field: field,
    minWidth: minWidth || 130,
    flex: flex,
    sortable: field !== "action",
  };
};
