import { Button, FormControl, Grid } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import GKDatePicker from "components/GKDatePicker";
import GKModal from "components/GKModal";
import GKTextField from "components/GKTextField";
import dayjs from "dayjs";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { QueryKeys } from "utils/queryKey";

const HistoricalModal = ({ open, setOpen, type, refetch }: any) => {
  const query = useQueryClient();
  const { id } = useParams();

  const { mutate: handleAddHMP } = useMutation({
    mutationKey: ["ADD_HMP"],
    mutationFn: (data: any) =>
      axiosInstance.post(`/admin-instrument-hmp/${id}/create/`, data),
    onSuccess: (response) => {
      refetch();
      toast.success(response?.data?.message || "Add HMP Successfully");
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const { mutate: handleUpdateCMP } = useMutation({
    mutationKey: ["UPDATE_CMP"],
    mutationFn: (data: any) =>
      axiosInstance.post(`/admin-instrument-cmp/${id}/update/`, data),
    onSuccess: (response) => {
      refetch();
      query.refetchQueries([QueryKeys.COMPANY_LIST]);
      toast.success(response?.data?.message || "Update CMP Successfully");
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const formik = useFormik({
    initialValues: {
      date: "",
      price: "",
    },
    onSubmit: (values: any) =>
      type === "HMP" ? handleAddHMP(values) : handleUpdateCMP(values),
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={type === "HMP" ? "Add HMP" : "Update CMP"}
      footer={
        <Grid container justifyContent={"flex-end"} gap={1}>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Close
          </Button>
          <Button
            type="submit"
            form="create-historical-modal-form"
            variant="outlined"
          >
            {type === "HMP" ? "Save" : "Update"}
          </Button>
        </Grid>
      }
    >
      <form id="create-historical-modal-form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl variant="standard" fullWidth>
              <GKDatePicker
                inputLabel="Date"
                name="date"
                value={dayjs(formik.values.date)}
                onChange={(value) => {
                  formik.setFieldValue(
                    "date",
                    dayjs(value).format("YYYY-MM-DD")
                  );
                }}
                disableFuture
                slotProps={{
                  textField: {
                    error: false,
                    helperText: false,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="Price"
                onChange={formik?.handleChange}
                value={formik.values.price}
                name="price"
                type="number"
              />
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </GKModal>
  );
};

export default HistoricalModal;
