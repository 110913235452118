import { Card, Grid, Typography } from "@mui/material";
import { theme } from "utils/Theme";
import { moneyCommaSeparator } from "utils/helpers";

interface PortfolioCardProps {
  companyDetails?: any;
}

function CompanyCard(props: PortfolioCardProps) {
  const { companyDetails } = props;

  const fields = [
    {
      name: "Company Name",
      value: companyDetails?.data?.name || "-",
      grid: 5,
    },
    {
      name: "ISIN",
      value: companyDetails?.data?.isinCode || "-",
      grid: 2.33,
    },
    {
      name: "NSE Code",
      value: companyDetails?.data?.nseCode || "-",
      grid: 2.33,
    },
    {
      name: "BSE Code",
      value: companyDetails?.data?.bseCode || "-",
      grid: 2.33,
    },
  ];

  return (
    <Card style={{ margin: 0 }}>
      <Grid container columnSpacing={3}>
        <Grid
          item
          container
          xs={9}
          mb={2}
          className="text-[#006480] text-[25px] mt-[-6px] font-bold relative"
        >
          <Grid>{companyDetails?.data?.name}:</Grid>
          <Grid ml={1}>
            {moneyCommaSeparator(Number(companyDetails?.data?.cmp))}
          </Grid>
        </Grid>
        <Grid
          item
          xs={3}
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <Typography fontSize={14} fontWeight={500}>
            {companyDetails?.data?.timestamp}
          </Typography>
        </Grid>
        <Grid item container xs={12} spacing={1}>
          {fields?.map((dataItem, index: number) => (
            <Grid item xs={dataItem.grid} key={index}>
              <Card
                style={{
                  margin: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundImage:
                    "linear-gradient(to right,#00658022, #7CA39222)",
                }}
              >
                <Typography fontSize={12} fontWeight={500}>
                  {dataItem.name}
                </Typography>
                <Typography
                  fontSize={14}
                  fontWeight={700}
                  color={theme.palette.primary.main}
                >
                  {dataItem.value}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Card>
  );
}

export default CompanyCard;
