import {
  InputLabel,
  TextareaAutosize,
  TextareaAutosizeProps,
  Typography,
  styled,
} from "@mui/material";
import { theme } from "utils/Theme";

interface GKTextAreaProps {
  inputLabel?: string;
  helperText?: string;
}

const BootstrapInput = styled(TextareaAutosize)(() => ({
  width: "100%",
  border: `1px solid ${theme.palette.primary?.main}`,
  borderRadius: "8px",
  fontSize: 12,
  padding: 8,
  "&:focus-visible": {
    outline: "none",
  },
}));

function GKTextArea(props: GKTextAreaProps & TextareaAutosizeProps) {
  const { inputLabel, id, name, helperText } = props;
  return (
    <>
      {inputLabel && (
        <InputLabel
          style={{
            color: theme.palette.primary.main,
            fontSize: "18px",
            fontFamily: "Manrope !important",
            textTransform: "capitalize",
            lineHeight: "1em",
          }}
          shrink
          htmlFor={name || id}
        >
          {inputLabel}
        </InputLabel>
      )}
      <BootstrapInput {...props} placeholder={inputLabel || "enter"} />
      {helperText && (
        <Typography
          fontWeight={500}
          fontSize={12}
          color={theme.palette.error.main}
        >
          {helperText}
        </Typography>
      )}
    </>
  );
}

export default GKTextArea;
