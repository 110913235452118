/* eslint-disable @typescript-eslint/no-unused-vars */
import { ToastColorTypes } from "components/ToastContainer";
import React from "react";

export const ToastContext = React.createContext({
  show: (color: ToastColorTypes, text: string) => {},
});

const useToast = () => React.useContext(ToastContext);

export default useToast;
