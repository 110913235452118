import { Grid, Tab, Tabs } from "@mui/material";
import DashboardLayout from "layouts/DashboardLayout";
import React, { useState } from "react";
import Group from "./Group";
import Template from "./Template";

const Communication = () => {
  const [communicationMainTabs, setCommunicationMainTabs] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCommunicationMainTabs(newValue);
  };

  return (
    <DashboardLayout
      title={communicationMainTabs === 1 ? "Groups" : "Templates"}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Tabs
            className="gridkey"
            variant="fullWidth"
            value={communicationMainTabs}
            onChange={handleChange}
          >
            <Tab disableRipple label="Templates" />
            <Tab disableRipple label="Groups" />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          {communicationMainTabs === 0 && <Template />}
          {communicationMainTabs === 1 && <Group />}
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default Communication;
