/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button, Grid, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import useToast from "hooks/useToast";
import { useAppDispatch } from "hooks/useToolkit";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import authService from "services/auth";
import { AuthenticationActions } from "services/redux/slices/Authentication";
import LogImg from "../../assets/login.png";

function LoginComponent() {
  const [loginCredentials, setLoginCredentials] = useState({
    phone: "",
    password: "",
  });
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { mutate: handleLogin, isLoading: isSubmitting } = useMutation({
    mutationFn: () => authService.login(loginCredentials),
    onSuccess: (response) => {
      dispatch(AuthenticationActions.Login(response.data!));
      navigate("/");
      window.location.reload();
    },
    onError: (error: any) => {
      if (error?.response?.status === 400) {
        toast.show("error", "Invalid phone or password, Please try again");
      } else if (error?.response?.data?.isAdmin === false) {
        toast.show("error", `You don't have access to this domain`);
      } else {
        toast.show("error", "Something went wrong, Please try again later");
      }
    },
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleLogin();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        height={"100vh"}
        width={"100vw"}
        position={"relative"}
      >
        <img
          src={LogImg}
          alt=""
          style={{
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            height: "100vh",
            width: "100vw",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 0,
          }}
        />
        <div
          style={{
            height: "100vh",
            width: "100vw",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1,
            backdropFilter: "blur(10px)",
          }}
        />
        <Grid
          item
          xs={6}
          display={"flex"}
          justifyContent={"center"}
          container
          alignItems={"center"}
          zIndex={3}
          spacing={2}
          sx={{
            backgroundColor: "#ffffff88",
            p: 10,
            boxShadow: `0 0 24px 9px #ffffff`,
            borderRadius: "10px",
          }}
        >
          <Grid
            item
            xs={8}
            display={"flex"}
            justifyContent={"center"}
            container
            alignItems={"center"}
            zIndex={2}
            spacing={2}
          >
            <Grid item xs={6} textAlign={"center"}>
              <img
                src="https://gridkey.in/Logos/Gridkey_BrandGuidelines-removebg-preview.png"
                alt=""
              />
            </Grid>
            <Grid item xs={12} textAlign={"center"}>
              <TextField
                fullWidth
                size="small"
                type="tel"
                label="Phone"
                required
                placeholder="Phone"
                value={loginCredentials.phone}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: 1,
                }}
                onChange={(e) =>
                  setLoginCredentials({
                    ...loginCredentials,
                    phone: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} textAlign={"center"}>
              <TextField
                label="Password"
                placeholder="Password"
                size="small"
                type="password"
                fullWidth
                required
                value={loginCredentials.password}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: 1,
                }}
                onChange={(e) =>
                  setLoginCredentials({
                    ...loginCredentials,
                    password: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} textAlign={"center"}>
              <Button
                fullWidth
                variant="contained"
                disabled={isSubmitting}
                type="submit"
              >
                Sign in
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

export default LoginComponent;
