import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import GKModal from "components/GKModal";
import GKTextField from "components/GKTextField";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { MdDelete } from "react-icons/md";
import { theme } from "utils/Theme";
import axiosInstance from "utils/axiosInstance";

interface Props {
  inModuleOpen: boolean;
  setIsModuleOpen: (Value: boolean) => void;
  moduleData: any;
  refetch: any;
}

const CreateModule = (props: Props) => {
  const { inModuleOpen, setIsModuleOpen, moduleData } = props;

  const { data, refetch } = useQuery({
    queryKey: ["GET-FAQ-MODULE"],
    queryFn: () => axiosInstance.get("/faq-modules/"),
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationKey: ["CREATE_MODULE"],
    mutationFn: (data: any) => axiosInstance.post(`/faq-modules/`, data),
    onSuccess: () => {
      refetch();
      setIsModuleOpen(false);
    },
  });

  const { mutate: deleteMutation } = useMutation({
    mutationKey: ["CREATE_MODULE"],
    mutationFn: (data: any) => axiosInstance.delete(`/faq-modules/${data}/`),
    onSuccess: () => {
      refetch();
    },
  });

  const formik = useFormik({
    initialValues: {
      question: moduleData?.question || "",
    },
    onSubmit: (values) => {
      mutate({
        module_name: values?.question,
      });
    },
  });

  return (
    <GKModal
      open={inModuleOpen}
      setOpen={setIsModuleOpen}
      modalTitle={"Add Module Details"}
      footer={
        <Grid container justifyContent={"flex-end"} gap={1}>
          <Button variant="outlined" onClick={() => setIsModuleOpen(false)}>
            Close
          </Button>
          <Button variant="contained" form="create-Module" type="submit">
            {isLoading ? <CircularProgress size={20} /> : "Submit"}
          </Button>
        </Grid>
      }
    >
      <form onSubmit={formik.handleSubmit} id="create-Module">
        <Grid item xs={12}>
          <FormControl variant="standard" fullWidth>
            <GKTextField
              inputLabel="text"
              required
              onChange={formik?.handleChange}
              value={formik.values.question}
              name="question"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {data?.data?.data?.map((dataItem: any) => {
            return (
              <Grid
                container
                p={1}
                m={1}
                border={`solid 1px ${theme.palette.primary.main}22`}
                alignItems={"center"}
                borderRadius={0}
              >
                <Grid item xs={6}>
                  {dataItem.moduleName || ""}
                </Grid>
                <Grid item xs={6} textAlign={"right"}>
                  <IconButton
                    color="error"
                    onClick={() => {
                      deleteMutation(dataItem.id);
                    }}
                  >
                    <MdDelete />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </form>
    </GKModal>
  );
};

export default CreateModule;
