/* eslint-disable @typescript-eslint/no-unused-vars */
import { Popover as HeadlessPopover, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Fragment } from "react";

interface PopoverProps {
  activator: React.ReactNode;
  children: React.ReactNode;
  toRight?: boolean;
}

function Popover(props: PopoverProps) {
  const { activator, children, toRight } = props;
  return (
    <HeadlessPopover className="relative">
      {({ open }) => (
        <>
          <HeadlessPopover.Button as="div">{activator}</HeadlessPopover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <HeadlessPopover.Panel
              className={classNames(
                "absolute z-50 mt-2 border rounded-lg border-light drop-shadow-lg bg-paper",
                toRight ? "right-0" : "left-0"
              )}
            >
              {children}
            </HeadlessPopover.Panel>
          </Transition>
        </>
      )}
    </HeadlessPopover>
  );
}

export default Popover;
