/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

export type ValidationStatus = true | string;
export type ValidationRule<T> =
  | ValidationStatus
  | ((value: T) => ValidationStatus);

export const FormContext = React.createContext({
  register: (_id: string, _validate: () => boolean): void => {},
  unregister: (_id: string): void => {},
});

export const useForm = () => {
  const form = useContext(FormContext);
  return form;
};

export const useValidation = (
  rules: ValidationRule<string>[],
  value: string
) => {
  const form = useForm();
  const id = uuidv4();
  const [error, setError] = React.useState("");
  const [touched, setTouched] = React.useState(false);

  const valRef = useRef(value);

  const validate = (val?: string) => {
    const valToTest = val || valRef.current;
    // eslint-disable-next-line no-restricted-syntax
    for (const rule of rules) {
      const handler = typeof rule === "function" ? rule : () => rule;
      const result = handler(valToTest);
      if (result !== true) {
        setError(result);
        return false;
      }
    }
    setError("");
    return true;
  };

  React.useEffect(() => {
    form.register(id, validate);
    return () => {
      form.unregister(id);
    };
  }, []);

  React.useEffect(() => {
    if (touched || (!touched && value !== "")) {
      valRef.current = value;
      validate();
      setTouched(true);
    }
  }, [value]);

  return {
    error,
    validate,
  };
};
