import React, { createContext, useMemo } from "react";

const FeeStructureContext = createContext({
  selectedIndex: 0,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setSelectedIndex: (index: number) => {},
});

export function FeeStructureProvider({ children }: any) {
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const FeeStructureContextValue = useMemo(
    () => ({
      selectedIndex,
      setSelectedIndex,
    }),
    [selectedIndex]
  );

  return (
    <FeeStructureContext.Provider value={FeeStructureContextValue}>
      {children}
    </FeeStructureContext.Provider>
  );
}

export default FeeStructureContext;
