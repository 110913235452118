import NumberInput from "./Number";
import PasswordInput from "./Password";
import TextInput from "./Text";

const toExport = {
  Text: TextInput,
  Number: NumberInput,
  Password: PasswordInput,
};

export default toExport;
