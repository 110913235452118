import { Card, Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import DashboardLayout from "layouts/DashboardLayout";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import DashboardCard from "./DashboardCard";

const Dashboard = () => {
  const { data, isFetching } = useQuery({
    queryKey: ["DASHBOARD_CARD_DATA"],
    queryFn: () => axiosInstance.get("/super_admin/counts/"),
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  return (
    <DashboardLayout
      title="Dashboard"
      breadcrumbs={[
        {
          name: "Dashboard",
          path: "/app/dashboard",
        },
      ]}
    >
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DashboardCard data={data?.data} isLoading={isFetching} />
          </Grid>
        </Grid>
      </Card>
    </DashboardLayout>
  );
};

export default Dashboard;
