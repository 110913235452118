/* eslint-disable no-alert */
import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import DragDrop from "components/DragDrop";
import GKModal from "components/GKModal";
import GKSearchSelect from "components/GKSearchSelect";
import Heading from "components/Heading";
import { useFormik } from "formik";
import { useState } from "react";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  selectedTemplate: any;
}

const SendMessageModal = (props: Props) => {
  const { open, setOpen, selectedTemplate } = props;

  const [userData, setUserData] = useState<any>([]);
  const [groupData, setGroupData] = useState<any>([]);

  const { isFetching } = useQuery({
    queryKey: ["GET_ALL_GROUP"],
    queryFn: () => axiosInstance.get("/advisor_group/"),
    onSuccess: (data) => {
      setGroupData(
        data.data.data.map((item: any) => ({
          name: item.title || "-",
          value: item.id,
        }))
      );
    },
  });

  const { isFetching: isLoading } = useQuery({
    queryKey: ["GET_ALL_USERS"],
    queryFn: () => axiosInstance.get("/super_admin/advisors/"),
    onSuccess: (data) => {
      setUserData(
        data.data.data.map((item: any) => ({
          name: `${item.name} - ${item.phone}` || "-",
          value: item.id,
        }))
      );
    },
  });

  const { mutate: handleSendMessage } = useMutation({
    mutationKey: ["SEND_MESSAGE"],
    mutationFn: (data: any) =>
      axiosInstance.post("/super_admin_whatsapp/send_msg/", data),
    onSuccess: (response) => {
      toast.success(response?.data?.message || "Message Sent Successfully");
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const formik = useFormik({
    initialValues: {
      file: [],
      templateId: selectedTemplate.id,
      // dynamicValues: "",
      broadcast: false,
      users: [],
      groupId: [],
    },
    onSubmit: (values) => {
      const data = new FormData();
      // let string = values.dynamicValues;

      // if (string.endsWith(",")) {
      //   string = string.slice(0, -1);
      // }

      // const resultArray = string?.length ? string?.split(",") : [];

      if (values.file) {
        data.append("file", values.file[0]);
      }

      data.append("templateId", values.templateId);
      // data.append("dynamicValues", JSON.stringify(resultArray));
      data.append("broadcast", JSON.stringify(values.broadcast));
      data.append("groupId", JSON.stringify(values.groupId));
      data.append("users", JSON.stringify(values.users));

      handleSendMessage(data);
    },
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={"Send Template"}
      footer={
        <Grid container justifyContent={"flex-end"} gap={1}>
          <Button type="submit" form="send-template-form" variant="outlined">
            Send
          </Button>
        </Grid>
      }
      titleComponent={
        <Grid item xs={12}>
          <FormControlLabel
            style={{
              marginLeft: 2,
            }}
            control={
              <Switch
                onChange={(event) => {
                  formik.setFieldValue("broadcast", event.target.checked);
                  formik.setFieldValue("users", []);
                  formik.setFieldValue("groupId", []);
                }}
                checked={formik.values.broadcast}
              />
            }
            labelPlacement="end"
            label={
              <b
                style={{
                  fontSize: 12,
                }}
              >
                Group
              </b>
            }
          />
        </Grid>
      }
    >
      <form id="send-template-form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          {selectedTemplate?.includeDocument && (
            <Grid item xs={12}>
              <Heading
                heading="Upload File"
                subHeading="Please upload the file in PDF and CSV format"
              />
              <DragDrop
                value={formik.values.file}
                supportName={".PDF, .CSV"}
                onChange={(e) => {
                  formik.setFieldValue("file", e);
                }}
              />
            </Grid>
          )}
          {/* <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="Dynamic Values"
                name="dynamicValues"
                value={formik.values.dynamicValues}
                onChange={formik.handleChange}
              />
            </FormControl>
          </Grid> */}

          <Grid item xs={12}>
            <GKSearchSelect
              multiple
              disableClearable={false}
              disableCloseOnSelect
              limitTags={5}
              loading={isFetching || isLoading}
              inputLabel={formik.values.broadcast ? "Group" : "User"}
              options={formik.values.broadcast ? groupData : userData || []}
              onChange={(e, val) => {
                const userId = val.map((item: any) => item.value);
                formik.setFieldValue(
                  formik.values.broadcast ? "groupId" : "users",
                  userId
                );
              }}
              value={
                formik.values.broadcast
                  ? groupData?.filter((option: any) =>
                      formik.values?.groupId?.includes(option.value)
                    )
                  : userData?.filter((option: any) =>
                      formik.values?.users?.includes(option.value)
                    ) || []
              }
              getOptionLabel={(option) => option.name || option}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select User" />
              )}
            />
          </Grid>
        </Grid>
      </form>
    </GKModal>
  );
};

export default SendMessageModal;
