import { Checkbox, Grid, Pagination, TableContainer } from "@mui/material";
import { DataGridPro, DataGridProProps } from "@mui/x-data-grid-pro";
import { CommonFilterState, useAppContext } from "AppContext";
import { useEffect, useState } from "react";
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdExpandLess,
  MdExpandMore,
  MdSearch,
} from "react-icons/md";
import { theme } from "utils/Theme";
import { CustomLoader, EmptyData } from "./DataGridUtils";
import GKTextField from "./GKTextField";

interface Props {
  headerComponent?: any;
  showSearch?: boolean;
  columnsButton?: boolean;
  filterButton?: boolean;
  searchPlaceholder?: string;
  showFilters?: boolean;
  ref?: any;
  CustomPagination?: any;
  exportCsv?: any;
  maxHeight?: any;
  showHeaderGrid?: any;
  zeroHolding?: boolean;
  setTableFilter?: any;
  minHeight?: any;
  tableFilter?: any;
  height?: string;
  tableName?: string;
  name: string;
  headerFilter?: any;
}

const GKTable = (props: DataGridProProps & Props) => {
  const {
    state: { commonFilter },
    dispatch,
    apiRef,
  } = useAppContext();

  const {
    rows,
    headerComponent,
    showSearch = true,
    loading,
    ref,
    CustomPagination,
    maxHeight,
    showHeaderGrid = true,
    pinnedColumns,
    onRowClick,
    height,
    minHeight,
    name,
    columns,
  } = props;

  const [isFocused, setIsFocused] = useState(false);

  const [searchText, setSearchText] = useState(
    commonFilter[name as keyof CommonFilterState]?.search
  );

  const CustomToolbar = (prProps: any) => {
    const { value, onInputChange } = prProps;

    return (
      showHeaderGrid && (
        <Grid container p={1} alignItems={"center"} ref={ref}>
          <Grid item xs={6}>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                gap: 4,
              }}
            >
              {showSearch && (
                <GKTextField
                  style={{ width: 250 }}
                  placeholder="Search"
                  sx={{
                    "& .MuiInputBase-root ": {
                      height: "35px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #E3E8EF",
                    },
                  }}
                  value={value}
                  onChange={onInputChange}
                  type="search"
                  autoFocus={isFocused}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  InputProps={{
                    startAdornment: (
                      <MdSearch size={22} color={theme.palette.grey[500]} />
                    ),
                  }}
                />
              )}
            </span>
          </Grid>
          <Grid item xs={6} display={"flex"} justifyContent={"flex-end"}>
            {headerComponent && headerComponent}
          </Grid>
        </Grid>
      )
    );
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      dispatch({
        type: "FILTER",
        data: {
          ...commonFilter,
          [name]: {
            ...commonFilter[name as keyof CommonFilterState],
            search: searchText?.trim(),
          },
        },
      });
    }, 400);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchText]);

  function CheckboxWrapper(props: any) {
    return (
      <Checkbox
        icon={<MdCheckBoxOutlineBlank />}
        checkedIcon={<MdCheckBox />}
        {...props}
      />
    );
  }

  return (
    <TableContainer>
      <DataGridPro
        {...props}
        apiRef={apiRef}
        getDetailPanelHeight={() => "auto"}
        sortingMode="server"
        filterMode="server"
        keepNonExistentRowsSelected
        disableRowSelectionOnClick
        sortModel={commonFilter[name as keyof CommonFilterState]?.sortModel}
        loading={loading}
        rows={loading ? [] : rows || []}
        columns={columns}
        onSortModelChange={(model) => {
          dispatch({
            type: "FILTER",
            data: {
              ...commonFilter,
              [name]: {
                ...commonFilter[name as keyof CommonFilterState],
                sortModel: model,
              },
            },
          });
        }}
        slotProps={{
          toolbar: {
            value: searchText,
            onInputChange: (event: any) => {
              setSearchText(event.target.value);
              dispatch({
                type: "FILTER",
                data: {
                  ...commonFilter,
                  [name]: {
                    ...commonFilter[name as keyof CommonFilterState],
                    page: 0,
                    skip: 0,
                  },
                },
              });
            },
          },
        }}
        slots={{
          toolbar: CustomToolbar,
          noRowsOverlay: EmptyData,
          loadingOverlay: CustomLoader,
          detailPanelExpandIcon: () => <MdExpandMore size={20} />,
          detailPanelCollapseIcon: () => <MdExpandLess size={20} />,
          footer: CustomPagination || Pagination,
          baseCheckbox: CheckboxWrapper,
        }}
        pinnedColumns={{
          ...pinnedColumns,
          right: ["action"],
        }}
        sx={{
          "& .MuiDataGrid-virtualScroller": {
            maxHeight: maxHeight || "calc(100vh - 256px)",
            minHeight: minHeight || "10px",
          },
          "&.MuiDataGrid-root .MuiTablePagination-root": {
            padding: "0 8px",
          },
          "& .MuiDataGrid-row": {
            "&:hover": {
              cursor: onRowClick ? "pointer" : "",
            },
          },
          minHeight: height,
          maxHeight: height,
        }}
      />
    </TableContainer>
  );
};

export default GKTable;
