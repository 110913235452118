import { LoginResponse } from "interfaces/Authentication";
import { fetchData } from "utils/api";

export default {
  login: async (loginCredentials: { phone: string; password: string }) =>
    fetchData<LoginResponse>(
      `${process.env.REACT_APP_BASE_URL}/login/`,
      "POST",
      loginCredentials,
      false
    ),
};
