/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import classNames from "classnames";
import Button from "components/Button";
import HoverableText from "components/HoverableText";
import Input from "components/Input";
import Popover from "components/Popover";
import Select from "components/Select";
import { useImmer } from "hooks/useImmer";
import usePagination from "hooks/usePagination";
import React, { useEffect, useState } from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import {
  MdChevronLeft,
  MdChevronRight,
  MdDescription,
  MdFirstPage,
  MdLastPage,
  MdSearch,
  MdSettings,
} from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { SortOrder, TableProps } from "./types";
import useColumns from "./useColumns";
import { useProcessedData } from "./useProcessedData";

function Table<T>(props: TableProps<T>) {
  const {
    columns,
    data,
    saveColumnPrefs = "",
    paper = false,
    showHoldingPage = false,
    isClickableField = false,
    clickableFieldName,
    clickableFieldLink,
    clickableRow = false,
    clickableRowLink,
    defaultItemsPerPage,
    holdingsFooter = false,
    holdingsFooterData,
    handleCheckboxSelectAll,
    isCheckboxChecked,
  } = props;

  const [sort, setSort] = useImmer<[string | null, SortOrder]>([null, "ASC"]);
  const [tableData, setTableData] = useState(data);

  const [query, setQuery] = useState("");

  // Process data
  const processedData = useProcessedData(columns, tableData, query, sort);

  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage ?? 10);
  const [cols, setCols, filteredColumns] = useColumns(columns, saveColumnPrefs);

  const { currentPage, setCurrentPage, currentData, totalPages } =
    usePagination(processedData, itemsPerPage);

  const navigate = useNavigate();

  useEffect(() => {
    setTableData(data);
  }, [data]);

  return (
    <div>
      <div
        className={classNames(
          "rounded-lg flex items-center bg-paper sticky top-0 z-[5]",
          paper ? "mb-1 py-2" : "shadow-md mb-2 px-4 py-2"
        )}
      >
        {/* main filter columns popover */}
        <Popover
          activator={
            <Button className="p-3 mr-2 text-2xl -mt-1" color="primary">
              <MdSettings />
            </Button>
          }
        >
          <div className="p-4 grid grid-cols-3 w-[630px] gap-4">
            {columns.map(
              (col, index) =>
                col.name !== "id" && (
                  <div key={col.name}>
                    <label className="flex-shrink-0 whitespace-nowrap cursor-pointer font-medium">
                      <input
                        type="checkbox"
                        checked={cols[index]}
                        onChange={() =>
                          // eslint-disable-next-line @typescript-eslint/no-shadow
                          setCols((cols) => {
                            if (col.forceShow && col.show !== false) return;
                            cols[index] = !cols[index];
                          })
                        }
                        name={col.name}
                        className="mr-2 accent-primary-dark cursor-pointer"
                      />
                      {col.name}
                    </label>
                  </div>
                )
            )}
          </div>
        </Popover>

        <Input.Text
          className="mr-2"
          variant="outlined"
          placeholder="Search"
          prependIcon={MdSearch}
          value={query}
          onChange={(e) => {
            setQuery(e);
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            currentPage !== 0 && setCurrentPage(0);
          }}
          noError
        />

        <div className="flex-grow" />
        <span className="mr-2 text-sm">
          <span className="text-light">
            {/* calculate the number of pages, and the first paramter doesn't exceed the total results */}
            {Math.min(currentPage * itemsPerPage + 1, processedData.length)}-
            {/* calculate the number of pages, and the second paramter doesn't exceed the total results */}
            {Math.min((currentPage + 1) * itemsPerPage, processedData.length)}{" "}
          </span>{" "}
          of {processedData.length}
        </span>
        <Button
          rounded
          className="mr-1 p-2"
          color="transparent"
          disabled={currentPage === 0}
          onClick={() => setCurrentPage((page) => page - 1)}
        >
          <MdChevronLeft className="text-xl" />
        </Button>
        <Button
          rounded
          className="p-2"
          color="transparent"
          disabled={currentPage === totalPages - 1}
          onClick={() => setCurrentPage((page) => page + 1)}
        >
          <MdChevronRight className="text-xl" />
        </Button>
        {/* move to first and last pages */}
        <Button
          rounded
          className="mr-1 p-2"
          color="transparent"
          disabled={currentPage === 0}
          onClick={() => setCurrentPage(0)}
        >
          <MdFirstPage className="text-xl" />
        </Button>
        <Button
          rounded
          className="mr-1 p-2"
          color="transparent"
          disabled={currentPage === totalPages - 1}
          onClick={() => setCurrentPage(totalPages - 1)}
        >
          <MdLastPage className="text-xl" />
        </Button>
        <Select
          className="w-20 ml-2"
          height="min-h-fit"
          value={itemsPerPage}
          onChange={(e) => {
            setItemsPerPage(Number(e));
            setCurrentPage((page) =>
              // Math.min(page, Math.ceil(processedData.length / Number(e)) - 1)
              Math.min(
                Math.floor((page * itemsPerPage) / Number(e)),
                Math.ceil(processedData.length / Number(e)) - 1
              )
            );
          }}
          items={[
            {
              name:
                processedData.length < 10 ? `${processedData.length}` : "10",
              value: 10,
            },
            {
              name: "25",
              value: 25,
            },
            {
              name: "50",
              value: 50,
            },
            {
              name: "100",
              value: 100,
            },
          ]}
        />
      </div>
      <div className="w-full overflowScrollXCustom">
        <table
          className={classNames(
            "bg-paper rounded-lg overflow-hidden w-full text-left table-auto overflow-x-scroll",
            paper ? "border-light border" : "shadow-md"
          )}
        >
          <thead className="border-b border-default">
            <tr>
              {filteredColumns.map((col) =>
                col.name === "Selected" ? (
                  <th
                    key={col.name}
                    className={classNames(
                      "p-2 xl:p-4 whitespace-nowrap text-black font-bold text-sm 2xl:text-base"
                    )}
                  >
                    <div
                      className={classNames(
                        "flex items-center group cursor-pointer",
                        col.type === "action" && "justify-center"
                      )}
                    >
                      <input
                        id="selectedItems"
                        type="checkbox"
                        className="focus:ring-primary-dark h-5 w-5 text-primary-dark rounded mr-1 cursor-pointer"
                        checked={isCheckboxChecked}
                        onChange={handleCheckboxSelectAll}
                      />
                      <label
                        htmlFor="selectedItems"
                        className="mr-1 cursor-pointer"
                      >
                        {col.name}
                      </label>
                    </div>
                  </th>
                ) : (
                  <th
                    key={col.name}
                    style={{
                      paddingRight:
                        // col type number and not col.renderer and not last col
                        // check if its not last column because the last column is the actions column
                        col.type === "number" &&
                        !col.renderer &&
                        filteredColumns.indexOf(col) !==
                          filteredColumns.length - 1
                          ? "2px"
                          : "",
                    }}
                    className={classNames(
                      "p-2 xl:p-4 whitespace-nowrap text-black font-bold text-sm 2xl:text-base"
                    )}
                    onClick={() => {
                      setSort((currVal) => {
                        if (currVal[0] === col.name) {
                          if (currVal[1] === "ASC") {
                            currVal[1] = "DESC";
                          } else {
                            currVal[0] = null;
                            currVal[1] = "ASC";
                          }
                        } else {
                          currVal[0] = col.name;
                          currVal[1] = "ASC";
                        }
                      });
                    }}
                  >
                    <div
                      className={classNames(
                        "flex items-center group cursor-pointer",
                        col.type === "number" && "justify-end",
                        col.type === "action" && "justify-center"
                      )}
                    >
                      <span className="mr-1">{col.name}</span>
                      <span className="w-2">
                        {sort[0] === col.name ? (
                          <AiOutlineArrowDown
                            className={classNames(
                              "transition-all text-default",
                              sort[1] === "ASC" ? "rotate-180" : ""
                            )}
                          />
                        ) : (
                          <AiOutlineArrowDown
                            className={classNames(
                              "group-hover:opacity-100 transition-opacity opacity-0 group-hover:text-light",
                              sort[1] === "ASC" ? "rotate-180" : ""
                            )}
                          />
                        )}
                      </span>
                    </div>
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody className="bg-trace">
            {holdingsFooter && (
              <tr className="bg-dark/30">
                {columns.map((col, i) =>
                  col.name !== "id" && col.name !== "Actions" ? (
                    <td
                      key={i}
                      className={classNames(
                        "p-3 text-sm 2xl:text-base",
                        col.type === "number" ? "text-right" : ""
                      )}
                    >
                      {col.name === "Invested Amount" && (
                        <span className="text-primary-dark">
                          <strong>
                            {
                              holdingsFooterData?.find(
                                (item) => item?.name === "Invested Amount"
                              )?.value
                            }
                          </strong>
                        </span>
                      )}
                      {col.name === "Current Amount" && (
                        <span className="text-primary-dark">
                          <strong>
                            {
                              holdingsFooterData?.find(
                                (item) => item?.name === "Current Amount"
                              )?.value
                            }
                          </strong>
                        </span>
                      )}
                      {col.name === "Unrealized Gain" && (
                        <span className="mr-3">
                          <strong
                            className={classNames(
                              holdingsFooterData
                                ?.find(
                                  (item) => item?.name === "Unrealized Gain"
                                )
                                ?.value.includes("-")
                                ? "text-error"
                                : "text-success"
                            )}
                          >
                            {
                              holdingsFooterData?.find(
                                (item) => item?.name === "Unrealized Gain"
                              )?.value
                            }{" "}
                          </strong>
                          /{" "}
                          <strong
                            className={classNames(
                              holdingsFooterData
                                ?.find(
                                  (item) => item?.name === "Unrealized Gain"
                                )
                                ?.percentage.includes("-")
                                ? "text-error"
                                : "text-success"
                            )}
                          >
                            {
                              holdingsFooterData?.find(
                                (item) => item?.name === "Unrealized Gain"
                              )?.percentage
                            }
                            %
                          </strong>
                        </span>
                      )}
                      {col.name === "Today's Gain" && (
                        <span
                          className={classNames(
                            "mr-3",
                            holdingsFooterData
                              ?.find((item) => item?.name === "Today's Gain")
                              ?.value.includes("-")
                              ? "text-error"
                              : "text-success"
                          )}
                        >
                          <strong
                            className={classNames(
                              holdingsFooterData
                                ?.find((item) => item?.name === "Today's Gain")
                                ?.value.includes("-")
                                ? "text-error"
                                : "text-success"
                            )}
                          >
                            {
                              holdingsFooterData?.find(
                                (item) => item?.name === "Today's Gain"
                              )?.value
                            }{" "}
                          </strong>
                          /{" "}
                          <strong
                            className={classNames(
                              holdingsFooterData
                                ?.find((item) => item?.name === "Today's Gain")
                                ?.percentage.includes("-")
                                ? "text-error"
                                : "text-success"
                            )}
                          >
                            {
                              holdingsFooterData?.find(
                                (item) => item?.name === "Today's Gain"
                              )?.percentage
                            }
                            %
                          </strong>
                        </span>
                      )}
                    </td>
                  ) : null
                )}
              </tr>
            )}
            {currentData.map((item, index) => (
              <React.Fragment key={index}>
                <tr
                  className={classNames(
                    "hover:bg-dark border-b border-light transition-colors",
                    clickableRow && "cursor-pointer"
                  )}
                  onClick={() => {
                    if (clickableRow) {
                      navigate(
                        `${clickableRowLink}/${item.gridKeyDisplayValue.id}`
                      );
                    }
                  }}
                >
                  {filteredColumns.map((col) => (
                    <td
                      key={col.name}
                      className={classNames(
                        "p-2 xl:px-4 text-sm text-light font-medium 2xl:text-base",
                        col.type === "number" || col.type === "action"
                          ? ""
                          : "max-w-[150px] truncate"
                      )}
                    >
                      {isClickableField && col.name === clickableFieldName ? (
                        <Link
                          to={`${clickableFieldLink}/${item.gridKeyDisplayValue["ISIN Number"]}`}
                          className="hover:underline"
                        >
                          {item.gridKeyDisplayValue[col.name]}
                        </Link>
                      ) : (
                        <span
                          className={classNames(
                            // if the length of the value is greater than 10 then apply the text-sm class
                            String(item.gridKeyDisplayValue[col.name]).length >
                              10
                              ? "truncate"
                              : "whitespace-nowrap",
                            col.type === "number" ? "flex justify-end" : ""
                          )}
                        >
                          {typeof item.gridKeyDisplayValue[col.name] ===
                          "string" ? (
                            <HoverableText>
                              {item.gridKeyDisplayValue[col.name]}
                            </HoverableText>
                          ) : (
                            item.gridKeyDisplayValue[col.name]
                          )}
                        </span>
                      )}
                    </td>
                  ))}
                  {/* show holding page */}
                  {showHoldingPage && (
                    <td className="p-3">
                      <Button
                        color="primary"
                        onClick={() => {
                          navigate(
                            `/holding-page/${item.gridKeyDisplayValue.id}`
                          );
                        }}
                      >
                        <MdDescription size={40} />
                      </Button>
                    </td>
                  )}
                  {/* ending of holding page */}
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Table;
