import { createTheme } from "@mui/material/styles";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    lightBg: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    bgColor: any;
    light: any;
    dark: any;
    border: any;
  }
  interface PaletteOptions {
    bgColor: any;
    dark: any;
    border: any;
    light: any;
  }
}

const paletteTheme = createTheme({
  palette: {
    primary: {
      main: "#01657F",
    },
    secondary: {
      main: "#7CA392",
    },
    error: {
      main: "#E84141",
    },
    success: {
      main: "#408140",
    },
    bgColor: {
      main: "#000000",
    },
    light: {
      main: "#FFFFFF",
    },
    dark: {
      main: "#000000",
    },
    border: {
      main: "#D7E7ED",
    },
  },
});

export const theme = createTheme(paletteTheme, {
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          fontFamily: "Manrope !important",
          backdropFilter: "blur(4px)",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: "Manrope",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: "Manrope",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Manrope",
          borderRadius: "8px",
          textTransform: "capitalize",
        },
      },
      variants: [
        {
          props: {
            size: "large",
            variant: "outlined",
            className: "extra-large",
          },
          style: {
            background: paletteTheme.palette.light?.main,
            padding: "12px 16px",
            borderRadius: "8px",
            color: paletteTheme.palette.dark.main,
            borderColor: paletteTheme.palette.border.main,
            textTransform: "capitalize",
            fontSize: 16,
            fontWeight: 400,
          },
        },
        {
          props: {
            variant: "contained",
            className: "light",
          },
          style: {
            background: `${paletteTheme.palette.border.main}33`,
            padding: "8px 10px",
            borderRadius: "8px",
            color: paletteTheme.palette.primary.main,
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
              background: `${paletteTheme.palette.border.main}22`,
            },
          },
        },
        {
          props: { variant: "lightBg", color: "primary" },
          style: {
            border: "none",
            background: `${paletteTheme.palette.primary.main}22`,
            color: `${paletteTheme.palette.primary.main}`,
            "&:hover": {
              background: `${paletteTheme.palette.primary.main}66`,
              color: `${paletteTheme.palette.primary.main}`,
            },
          },
        },
        {
          props: { variant: "lightBg", color: "info" },
          style: {
            border: "none",
            background: `${paletteTheme.palette.info.main}22`,
            color: `${paletteTheme.palette.info.main}`,
            "&:hover": {
              background: `${paletteTheme.palette.info.main}66`,
              color: `${paletteTheme.palette.info.main}`,
            },
          },
        },
        {
          props: { variant: "lightBg", color: "error" },
          style: {
            border: "none",
            background: `${paletteTheme.palette.error.main}22`,
            color: `${paletteTheme.palette.error.main}`,
            "&:hover": {
              background: `${paletteTheme.palette.error.main}66`,
              color: `${paletteTheme.palette.error.main}`,
            },
          },
        },
        {
          props: { variant: "lightBg", color: "secondary" },
          style: {
            border: "none",
            background: `${paletteTheme.palette.secondary.main}22`,
            color: `${paletteTheme.palette.secondary.main}`,
            "&:hover": {
              background: `${paletteTheme.palette.secondary.main}66`,
              color: `${paletteTheme.palette.secondary.main}`,
            },
          },
        },
      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          fontFamily: "Manrope",
          borderColor: paletteTheme.palette.border.main,
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          fontFamily: "Manrope",
          backgroundColor: `${paletteTheme?.palette?.primary?.main} !important`,
          color: `${paletteTheme?.palette?.light?.main}`,
          fontWeight: 700,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          fontFamily: "Manrope",
          border: "solid 1px",
          borderColor: paletteTheme.palette.border.main,
          padding: "24px",
          boxShadow: "none",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          fontFamily: "Manrope",
          "& .MuiTableCell-root ": {
            fontWeight: 600,
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          fontFamily: "Manrope",
          // overflow: 'auto',
        },
      },
    },
    MuiAlert: {
      variants: [
        {
          props: { severity: "info" },
          style: {
            color: paletteTheme.palette.info.main,
            background: "#E5F6FD",
          },
        },
        {
          props: { severity: "success" },
          style: {
            color: paletteTheme.palette.success.main,
            background: "#EDF7ED",
          },
        },
        {
          props: { severity: "warning" },
          style: {
            color: paletteTheme.palette.warning.main,
            background: "#FFF4E5",
          },
        },
        {
          props: { severity: "error" },
          style: {
            color: paletteTheme.palette.error.main,
            background: "#FDEDED",
          },
        },
      ],
      styleOverrides: {
        root: {
          fontFamily: "Manrope",
          alignItems: "center",
          fontWeight: 600,
          fontSize: "13px",
          "& .MuiAlert-action ": {
            paddingTop: 0,
            "& .MuiButtonBase-root": {
              fontFamily: "Manrope",
              background: "transparent",
              color: "inherit",
              border: "none",
            },
            "& .MuiButtonBase-root:hover": {
              background: "transparent",
              border: "none",
              color: "inherit",
            },
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          fontFamily: "Manrope",
          border: "solid 1px",
          borderColor: paletteTheme.palette.border.main,
          fontFamaily: "Manrope",
          borderRadius: "8px",
          "& .MuiDataGrid-root ": {
            flexDirection: "column",
            borderRadius: "8px",
            background: paletteTheme.palette.light?.main,
            fontSize: 14,
            border: "none",
            // "&:hover": {
            //   cursor: "pointer",
            // },
            "& .MuiDataGrid-row:nth-of-type(even)": {
              background: paletteTheme.palette.grey[100],
            },
          },
          "& .MuiDataGrid-iconSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-overlayWrapper": {
            height: "100%",
          },
          "& .MuiDataGrid-overlayWrapper .MuiDataGrid-overlayWrapperInner": {
            height: "100% !important",
          },
          "& .MuiDataGrid-root .MuiDataGrid-withBorderColor  ": {
            border: "none",
          },
          "& .MuiPagination-ul": {
            justifyContent: "flex-end",
          },
          "& .MuiDataGrid-cellContent": {
            fontFamily: "Manrope !important",
            textTransform: "capitalize !important",
          },
          "& .MuiDataGrid-row:hover": {
            background: `${paletteTheme?.palette.primary.main}22 !important`,
            color: paletteTheme?.palette.primary.main,
          },
          "& .MuiPaginationItem-outlined": {
            background: paletteTheme.palette.border.main,
            border: "none",
            color: paletteTheme.palette.primary.main,
            "& .MuiSvgIcon-root": {
              height: 25,
              width: 25,
            },
          },
          "& .MuiPaginationItem-page": {
            display: "none",
          },
          "& .MuiPaginationItem-ellipsis": {
            display: "none",
          },
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader ": {
            outline: "none",
            borderTop: `solid 1px ${paletteTheme.palette.border.main}44`,
            borderBottom: `solid 1px ${paletteTheme.palette.border.main}44`,
            color: paletteTheme.palette.dark.main,
          },
          "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within ": {
            outline: "none",
          },
          "& .MuiDataGrid-root .MuiDataGrid-toolbarContainer ": {
            marginLeft: "auto",
            gap: 0,
            marginBottom: "4px",
          },
          "& .MuiDataGrid-root .MuiInputBase-root  ": {
            margin: "0 4px",
          },
          "& .MuiDataGrid-root .MuiToolbar-root  ": {
            marginLeft: "8px",
            padding: 0,
          },
          "& .MuiDataGrid-footerContainer": {
            display: "none",
            border: "none",
            borderBottom: "1px solid",
            borderColor: paletteTheme.palette.border.main,
          },
          "& .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle ": {
            fontWeight: 700,
            fontSize: 14,
            fontFamily: "Manrope !important",
            textTransform: "capitalize",
          },
          "& .MuiDataGrid-columnHeader .MuiButtonBase-root": {
            background: "transparent",
            border: "none",
            padding: 0,
          },
          "& .MuiDataGrid-columnHeader .MuiButtonBase-root:hover": {
            background: "transparent",
            padding: 0,
            color: paletteTheme.palette.dark.main,
            border: "none",
          },
          "& .MuiDataGrid-pinnedColumns ": {
            boxShadow: "none",
          },
          "& .MuiDataGrid-pinnedColumnHeaders  ": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: "Manrope",
          whiteSpace: "nowrap",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          fontFamily: "Manrope",
          "& td,th": {
            padding: "18px",
          },
          "& .MuiTableBody-root tr:nth-of-type(odd)": {
            background: paletteTheme.palette.light?.main,
          },
          "& .MuiTableBody-root tr:nth-of-type(even)": {
            background: paletteTheme.palette.grey[100],
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          fontFamily: "Manrope",
          padding: "4px",
          background: `${paletteTheme.palette.primary.main}33`,
          color: `${paletteTheme.palette.primary.main}`,
          border: `solid 1px transparent`,
          transition: "all .3s",
          "&:hover": {
            border: `solid 1px ${paletteTheme.palette.primary.main}`,
            background: `${paletteTheme.palette.primary.main}`,
            color: paletteTheme.palette.light?.main,
          },
        },
      },
      variants: [
        {
          props: { color: "error" },
          style: {
            background: `${paletteTheme.palette.error.main}33`,
            color: `${paletteTheme.palette.error.main}`,
            border: `solid 1px transparent`,
            transition: "all .3s",
            "&:hover": {
              border: `solid 1px ${paletteTheme.palette.error.main}`,
              background: `${paletteTheme.palette.error.main}`,
              color: paletteTheme.palette.light?.main,
            },
          },
        },
        {
          props: { color: "info" },
          style: {
            background: `${paletteTheme.palette.info.main}33`,
            color: `${paletteTheme.palette.info.main}`,
            border: `solid 1px transparent`,
            transition: "all .3s",
            "&:hover": {
              border: `solid 1px ${paletteTheme.palette.info.main}`,
              background: `${paletteTheme.palette.info.main}`,
              color: paletteTheme.palette.light?.main,
            },
          },
        },
        {
          props: { color: "success" },
          style: {
            background: `${paletteTheme.palette.success.main}33`,
            color: `${paletteTheme.palette.success.main}`,
            border: `solid 1px transparent`,
            transition: "all .3s",
            "&:hover": {
              border: `solid 1px ${paletteTheme.palette.success.main}`,
              background: `${paletteTheme.palette.success.main}`,
              color: paletteTheme.palette.light?.main,
            },
          },
        },
        {
          props: { color: "primary" },
          style: {
            background: `${paletteTheme.palette.primary.main}33`,
            color: `${paletteTheme.palette.primary.main}`,
            border: `solid 1px transparent`,
            transition: "all .3s",
            "&:hover": {
              border: `solid 1px ${paletteTheme.palette.primary.main}`,
              background: `${paletteTheme.palette.primary.main}`,
              color: paletteTheme.palette.light?.main,
            },
          },
        },
      ],
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: "filled" },
          style: {
            background: `${paletteTheme.palette.primary.main}1A`,
            "& .MuiInputBase-root:focus-visible": {
              outline: 0,
            },
            "&  input": {
              color: paletteTheme.palette.primary.main,
              border: "none !important",
            },
            "&  .MuiFilledInput-root:before": {
              border: "none !important",
            },
            "&  .Mui-focused:before": {
              border: "none !important",
              outline: "none !important",
            },
            "&  .Mui-focused:after": {
              border: "none !important",
              outline: "none !important",
              borderRadius: "8px",
            },
          },
        },
      ],
    },
    MuiAutocomplete: {
      variants: [
        {
          props: { className: "filled" },
          style: {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },

            "& .MuiInputBase-root ": {
              paddingTop: 0,
              paddingBottom: 0,
              background: paletteTheme.palette.border.main,
              outline: 0,
            },

            "& .MuiButtonBase-root  ": {
              border: "none",
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          fontFamily: "Manrope",
          "& .MuiChip-root": {
            border: "solid 1px",
            borderColor: paletteTheme?.palette?.primary.main,
            color: paletteTheme?.palette?.primary.main,
            background: `${paletteTheme?.palette?.primary.main}22`,
            borderRadius: "8px",
            fontWeight: 600,
            height: "20px",
            "& .MuiChip-label": {
              padding: "0 4px",
            },
            "& .MuiChip-deleteIcon": {
              margin: "0 !important",
              color: paletteTheme.palette.primary.main,
              height: "100%",
              transition: "all .3s",
            },
            "& .MuiChip-deleteIcon:hover": {
              color: paletteTheme.palette.error.main,
              background: `${paletteTheme.palette.error.main}33`,
              transition: "all .3s",
            },
          },
        },
      },
    },
    MuiTabs: {
      variants: [
        {
          props: { variant: "fullWidth" },
          style: {
            background: paletteTheme.palette.light?.main,
            borderRadius: "8px",
            minHeight: 40,
            border: "solid 1px",
            borderColor: paletteTheme.palette.border.main,
            transition: "all .3s ",
            "& .MuiTabs-indicator": {
              height: 0,
            },
            "& .Mui-selected": {
              minHeight: 40,
              background: `linear-gradient(91.84deg,${paletteTheme.palette.primary.main} 0%, ${paletteTheme.palette.secondary.main} 100%)`,
              borderRadius: "8px",
              color: `${paletteTheme.palette.light?.main} !important`,
            },
            "& button": {
              padding: 0,
              minHeight: 40,
              fontSize: "14px",
              fontWeight: 500,
              textTransform: "capitalize !important",
            },
          },
        },
        {
          props: { variant: "standard" },
          style: {
            background: paletteTheme.palette.light?.main,
            borderRadius: "8px",
            minHeight: 36,
            minWidth: 70,
            border: `solid 1px ${paletteTheme.palette.border.main}`,
            transition: "all .3s ",
            "& .MuiTabs-indicator": {
              height: 0,
            },
            "& .Mui-selected": {
              minHeight: 36,
              minWidth: 70,
              background: `linear-gradient(91.84deg,${paletteTheme.palette.primary.main} 0%, ${paletteTheme.palette.secondary.main} 100%)`,
              borderRadius: "8px",
              color: `${paletteTheme.palette.light?.main} !important`,
            },
            "& button": {
              padding: 0,
              minHeight: 36,
              minWidth: 70,
              fontSize: "12px",
              fontWeight: 500,
              textTransform: "capitalize !important",
            },
          },
        },
      ],
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          fontFamily: "Manrope",
          margin: "0 !important",
          "&.Mui-expanded ": {
            background: `${paletteTheme.palette.primary.main}11`,
            color: `${paletteTheme.palette.primary.main}`,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Manrope !important",
        },
      },
    },
  },
});
