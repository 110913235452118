import { Card, Grid, Tab, Tabs } from "@mui/material";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import AdvisorTable from "./AdvisorTable";
import ClientsTable from "./ClientsTable";
import EmployeeTable from "./EmployeeTable";
import EquityBasketTable from "./EquityBasketTable";
import PartnerTable from "./PartnerTable";

const UserDetails = () => {
  // const navigate = useNavigate();
  const profileTabs = [
    "Advisor",
    "Employee",
    "Clients",
    "Partner",
    "Equity Basket",
  ];
  const [activeTab, setActiveTab] = useState(0);

  return (
    <DashboardLayout
      title="User Details"
      breadcrumbs={[
        {
          name: "Dashboard",
          path: "/app/dashboard",
        },
        {
          name: "User Details",
          path: "",
        },
      ]}
      // toolbar={
      //   <Button onClick={() => navigate(-1)}>
      //     Back &nbsp; <TbArrowBackUp />
      //   </Button>
      // }
    >
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Tabs
              variant="fullWidth"
              value={activeTab}
              onChange={(e, val) => {
                setActiveTab(val);
              }}
            >
              {profileTabs?.map((dataItem) => (
                <Tab key={dataItem} label={dataItem} />
              ))}
            </Tabs>
          </Grid>

          <Grid item xs={12}>
            {activeTab === 0 && <AdvisorTable />}
            {activeTab === 1 && <EmployeeTable />}
            {activeTab === 2 && <ClientsTable />}
            {activeTab === 3 && <PartnerTable />}
            {activeTab === 4 && <EquityBasketTable />}
          </Grid>
        </Grid>
      </Card>
    </DashboardLayout>
  );
};

export default UserDetails;
