import { Card, Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import DashboardLayout from "layouts/DashboardLayout";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import BrokerPreference from "./BrokerPreference";
import Dividend from "./Dividend";
import WalletTopup from "./WalletTopup";

const Preference = () => {
  const { id } = useParams();

  // const navigate = useNavigate();

  // const [expanded, setExpanded] = useState<number>(0);

  const { data, refetch, isFetched } = useQuery({
    queryKey: ["GET_BROKER"],
    queryFn: () =>
      axiosInstance.get(`/super_admin/${id}/get_broker_integration_status/`),
  });

  // const {
  //   data: dividend,
  //   refetch: dividendRefetch,
  //   isFetched: dividendFetching,
  // } = useQuery({
  //   queryKey: ["GET_DIVIDEND"],
  //   queryFn: () =>
  //     axiosInstance.get(`/update-take-dividend/advisor-wise/`, {
  //       params: {
  //         advisor: id,
  //       },
  //     }),
  //   onError: (error: any) => {
  //     toast.error(error?.response?.data?.message || "Something went wrong");
  //   },
  // });

  // const { mutate } = useMutation({
  //   mutationKey: ["UPDATE_DIVIDEND"],
  //   mutationFn: (data: any) =>
  //     axiosInstance.patch(`/update-take-dividend/advisor-wise/?advisor=${id}`, {
  //       dividend: data.dividend,
  //     }),
  //   onSuccess: (response) => {
  //     dividendRefetch();
  //      toast.success(response?.data?.message || "Divided Update Successfully")
  //   },
  //   onError: (error: any) => {
  //    toast.error(error?.response?.data?.message || "Something went wrong");
  //   },
  // });

  // const formik = useFormik({
  //   enableReinitialize: dividendFetching,
  //   initialValues: {
  //     dividend: dividend?.data?.data?.dividend || false,
  //   },
  //   onSubmit: (values: any) => {
  //     mutate(values);
  //   },
  // });

  return (
    <DashboardLayout
      title="Preference"
      breadcrumbs={[
        {
          name: "Dashboard",
          path: "/app/dashboard",
        },
        {
          name: "User Details",
          path: "/app/dashboard/user-details",
        },
        {
          name: "Preference",
          path: "",
        },
      ]}
      // toolbar={
      //   <Button onClick={() => navigate(-1)}>
      //     Back &nbsp; <TbArrowBackUp />
      //   </Button>
      // }
    >
      <Card>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <BrokerPreference
              data={data}
              isFetched={isFetched}
              refetch={refetch}
            />
          </Grid>
          <Grid item xs={12}>
            <Dividend data={data} isFetched={isFetched} refetch={refetch} />
          </Grid>
          <Grid item xs={12}>
            <WalletTopup />
          </Grid>
        </Grid>
      </Card>
    </DashboardLayout>
  );
};

export default Preference;
