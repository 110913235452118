import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import GKClientDataGrid from "components/GKClientDataGrid";
import StatusField from "components/StatusField";
import dayjs from "dayjs";
import { useState } from "react";
import { IoIosSend } from "react-icons/io";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField } from "utils/helpers";
import SendMessageModal from "./SendMessageModal";
import TemplateModal from "./TemplateModal";

const Template = () => {
  const [templateModal, setTemplateModal] = useState(undefined);
  const [templateData, setTemplateData] = useState<any>({});
  const [sendTemplateModal, setSendTemplateModal] = useState(undefined);
  const [selectedTemplate, setSelectedTemplate] = useState<any>({});

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["GET_ALL_TEMPLATES"],
    queryFn: () => axiosInstance.get("/super_admin_whatsapp/template/"),
  });

  const columns: GridColDef[] = [
    { ...extraColumnsField("Name", "templateName", 150, 1) },
    {
      ...extraColumnsField("Document", "includeDocument", 150, 1),
      renderCell: (params) => (
        <StatusField
          statusString={params?.row?.includeDocument ? "true" : "false"}
        />
      ),
    },
    {
      ...extraColumnsField("Approved Status", "approvedStatus", 150, 1),
      renderCell: (params) => (
        <StatusField
          statusString={params?.row?.approvedStatus ? "true" : "false"}
        />
      ),
    },
    {
      ...extraColumnsField("Created At", "createdAt", 150, 1),
      valueFormatter: (params) => dayjs(params?.value).format("DD MMM YYYY"),
      valueGetter: (row) => row?.row?.createdAt || "N/A",
    },
    {
      ...extraColumnsField("Action", "action", 80, 1),
      headerAlign: "right",
      align: "right",
      type: "action",
      renderCell: (params) => (
        <Grid container gap={1} justifyContent={"flex-end"}>
          <Tooltip title="Send Message">
            <IconButton
              color="primary"
              onClick={(event) => {
                event.stopPropagation();
                setSelectedTemplate(params.row);
                setSendTemplateModal(!sendTemplateModal);
              }}
            >
              <IoIosSend size={20} />
            </IconButton>
          </Tooltip>
        </Grid>
      ),
    },
  ];

  return (
    <>
      <GKClientDataGrid
        headerComponent={
          <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
            <Button
              variant="contained"
              onClick={() => {
                setTemplateData({});
                setTemplateModal(!templateModal);
              }}
            >
              Create Template
            </Button>
          </Grid>
        }
        loading={isFetching}
        showFiltersButton={false}
        columns={columns}
        rows={data?.data?.data || []}
        onRowClick={(row) => {
          setTemplateData(row.row);
          setTemplateModal(true);
        }}
      />

      {templateModal && (
        <TemplateModal
          open={templateModal}
          setOpen={setTemplateModal}
          refetch={refetch}
          templateData={templateData}
        />
      )}

      {sendTemplateModal && (
        <SendMessageModal
          open={sendTemplateModal}
          setOpen={setSendTemplateModal}
          selectedTemplate={selectedTemplate}
        />
      )}
    </>
  );
};

export default Template;
