export const preferencesSetting = [
  {
    mainHeading: "Ledger",
    details: [
      {
        heading: "Include Dividend",
        value: false,
      },
    ],
  },
];

export const brokerSupport = [
  {
    mainHeading: "Broker Support",
    details: [
      {
        heading: "Aditya Birla",
        key: "adityaBirlaSupport",
        value: false,
      },
      {
        heading: "PMS Support",
        key: "pmsSupport",
        value: false,
      },
      {
        heading: "RA Support",
        key: "isRaAccess",
        value: false,
      },
      {
        heading: "Latin Manherlal",
        key: "lmSupport",
        value: false,
      },
    ],
  },
];
