import { Button, Grid } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import GKDataGrid from "components/GKDataGrid";
import { useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField } from "utils/helpers";
import AddIdenifier from "./AddIdentifierModal";

function PricesPage() {
  const { id } = useParams();
  const [identifierRowData, setIdentifierRowData] = useState({});
  const [isOpenIdentifier, setIsOpenIdentifier] = useState(undefined);

  const {
    state: { currentUser },
  } = useAppContext();

  const isDisabled = currentUser?.phone === "9911223344";

  const {
    data: priceData,
    isFetching: priceFetching,
    refetch,
  } = useQuery({
    queryKey: ["ADMIN_INSTRUMENT_META"],
    queryFn: async () => axiosInstance.get(`/admin-instrument-meta/${id}/`),
  });

  const priceColumns: GridColDef[] = [
    {
      ...extraColumnsField("Code Type", "codeType", 150, 1),
    },
    {
      ...extraColumnsField("Code", "code", 150, 1),
    },
    {
      ...extraColumnsField("Status", "status", 150, 1),
    },
  ];
  return (
    <Grid container>
      <Grid item xs={12} mb={2} display={"flex"} justifyContent={"end"}>
        <Button
          variant="contained"
          size="medium"
          onClick={() => {
            setIdentifierRowData({});
            setIsOpenIdentifier(!isOpenIdentifier);
          }}
          disabled={isDisabled}
        >
          Add identifiers
        </Button>
      </Grid>
      <Grid item xs={12}>
        <GKDataGrid
          loading={priceFetching}
          columns={priceColumns}
          rows={
            priceData?.data?.data?.map((data: any, index: number) => {
              return {
                ...data,
                id: index,
              };
            }) || []
          }
          style={{ cursor: "pointer" }}
          onRowClick={(row) => {
            setIdentifierRowData(row.row);
            setIsOpenIdentifier(true);
          }}
        />
      </Grid>

      {isOpenIdentifier && (
        <AddIdenifier
          isModalOpen={isOpenIdentifier}
          setIsModalOpen={setIsOpenIdentifier}
          refetch={refetch}
          identifierRowData={identifierRowData}
        />
      )}
    </Grid>
  );
}

export default PricesPage;
