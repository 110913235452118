import { useGridApiRef } from "@mui/x-data-grid";
import { GridApiPro } from "@mui/x-data-grid-pro";
import React, {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useReducer,
} from "react";

// ========================================================================================//
export interface FilterValue {
  skip?: number;
  page?: number;
  take?: number;
  sortModel?: any[];
  filterColumn?: any[];
  search?: string;
  showNonZero?: boolean;
  customFilter?: any;
}

export const defaultFilterValue: FilterValue = {
  skip: 0,
  page: 0,
  take: 25,
  sortModel: [],
  filterColumn: [],
  search: "",
  showNonZero: false,
  customFilter: {},
};

export interface CommonFilterState {
  companyMaster: FilterValue;
  uniqueCompany: FilterValue;
  historicalPrice: FilterValue;
  advisor: FilterValue;
  client: FilterValue;
  employee: FilterValue;
  equityBasket: FilterValue;
  partner: FilterValue;
}

export const createCommonFilter = (): FilterValue => ({
  ...defaultFilterValue,
});

export const initialCommonFilterState: CommonFilterState = {
  companyMaster: createCommonFilter(),
  uniqueCompany: createCommonFilter(),
  historicalPrice: createCommonFilter(),
  advisor: createCommonFilter(),
  client: createCommonFilter(),
  employee: createCommonFilter(),
  equityBasket: createCommonFilter(),
  partner: createCommonFilter(),
};

// ========================================================================================//

interface State {
  currentUser: any;
  commonFilter: CommonFilterState;
}

interface Action {
  type: string;
  data?: any;
}

interface AppContextType {
  state: State;
  dispatch: React.Dispatch<Action>;
  apiRef: any;
}

const initialState: State = {
  currentUser: {},
  commonFilter: initialCommonFilterState,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "CURRENT_USER":
      return { ...state, currentUser: action.data };
    case "FILTER":
      return { ...state, commonFilter: action.data };

    default:
      return { ...state };
  }
};

const AppContext = createContext<AppContextType | undefined>(undefined);

interface AppContextProviderProps {
  children: ReactNode;
}

const AppContextProvider: React.FC<AppContextProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const apiRef = useGridApiRef<GridApiPro>();

  const value = useMemo(
    () => ({ state, dispatch, apiRef }),
    [state, dispatch, apiRef]
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

const useAppContext = (): AppContextType => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppContextProvider");
  }
  return context;
};

export { AppContextProvider, useAppContext };
