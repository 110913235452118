export const items = [
  {
    name: "Management Fee",
    value: "managementFee",
  },
  {
    name: "Performance Fee",
    value: "performanceFee",
  },
  {
    name: "Brokerage",
    value: "brokerage",
  },
  {
    name: "Exit Load",
    value: "exitLoad",
  },
];

export const dummySelectItems = [
  {
    name: "Simple",
    value: "simple",
  },
  {
    name: "Complex",
    value: "complex",
  },
  {
    name: "Custom",
    value: "custom",
  },
];
export const highWaterMarkItems = [
  {
    name: "High Water Mark",
    value: "highWaterMark",
  },
];
