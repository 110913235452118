import { Button, FormControl, Grid, IconButton, MenuItem } from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import { useRef } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { FILTER_OPERATOR } from "utils/constants";
import * as Yup from "yup";
import GKModal from "./GKModal";
import GKSelect from "./GKSelect";
import GKTextField from "./GKTextField";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  columns: any;
  setFilterColumn: any;
  filterColumn: any;
  paginationData: any;
  setPaginationData: any;
}

function ComanFilterModal(props: Props) {
  const {
    isModalOpen,
    setIsModalOpen,
    columns,
    setFilterColumn,
    filterColumn,
    paginationData,
    setPaginationData,
  } = props;

  const validationSchema = Yup.object().shape({
    filters: Yup.array().of(
      Yup.object().shape({
        field: Yup.string().required(),
        // operator: Yup.string().required(),
        value: Yup.string().required(),
      })
    ),
  });

  const ArrayHelperRef = useRef(null);

  const formikRef = useRef(null);

  return (
    <GKModal
      open={isModalOpen}
      setOpen={setIsModalOpen}
      modalTitle="Filters Modal"
      footer={
        <Button type="submit" form="filter" variant="contained" size="medium">
          Submit
        </Button>
      }
      titleComponent={
        <Grid container justifyContent={"flex-end"} gap={1}>
          <Button
            onClick={() => {
              ArrayHelperRef.current.push({
                field: "",
                operator: "icontains",
                value: "",
              });
            }}
            size="medium"
            variant="outlined"
          >
            Add Filters
          </Button>
          <Button
            onClick={() => {
              setFilterColumn([]);
              setPaginationData({
                ...paginationData,
                page: 0,
                skip: 0,
              });
              formikRef.current.handleReset();
              setIsModalOpen(false);
            }}
            size="medium"
            variant="outlined"
            color="error"
          >
            Reset
          </Button>
        </Grid>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Formik
            innerRef={formikRef}
            validationSchema={validationSchema}
            initialValues={{
              filters: filterColumn?.filters?.length
                ? filterColumn?.filters?.map((dataItem: any) => ({
                    field: dataItem.field,
                    operator: "icontains",
                    value: dataItem.value,
                  }))
                : [{ field: "", operator: "icontains", value: "" }],
            }}
            onSubmit={(values) => {
              setFilterColumn(values);
              setPaginationData({
                ...paginationData,
                page: 0,
                skip: 0,
              });
              setIsModalOpen(false);
            }}
          >
            {({ values, handleChange }) => {
              return (
                <Form id="filter">
                  <FieldArray
                    name="filters"
                    render={(arrayHelper) => {
                      ArrayHelperRef.current = arrayHelper;
                      return (
                        <Grid container>
                          {values.filters.map(
                            (dataItem: any, index: number) => {
                              return (
                                <Grid item xs={12} spacing={2} mb={1} container>
                                  <Grid item xs={5}>
                                    <FormControl variant="standard" fullWidth>
                                      <GKSelect
                                        className="col-span-6"
                                        name={`filters.${index}.field`}
                                        inputLabel="Column"
                                        value={values.filters[index].field}
                                        onChange={handleChange}
                                      >
                                        <MenuItem
                                          style={{ display: "none" }}
                                          value=""
                                        >
                                          Select Column
                                        </MenuItem>
                                        {columns
                                          ?.filter(
                                            (data: any) =>
                                              data?.field !== "action" &&
                                              data?.field?.toLowerCase() !==
                                                "status" &&
                                              data?.field?.toLowerCase() !==
                                                "actions"
                                          )
                                          .map((dataItem: any, i: number) => (
                                            <MenuItem
                                              key={i}
                                              value={dataItem.field}
                                            >
                                              {dataItem.headerName}
                                            </MenuItem>
                                          ))}
                                      </GKSelect>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={4} display={"none"}>
                                    <FormControl variant="standard" fullWidth>
                                      <GKSelect
                                        className="col-span-6"
                                        name={`filters.${index}.operator`}
                                        inputLabel="Operator"
                                        value={
                                          values.filters[index].operator ||
                                          "icontains"
                                        }
                                        onChange={handleChange}
                                      >
                                        <MenuItem
                                          style={{ display: "none" }}
                                          value=""
                                        >
                                          Select Operator
                                        </MenuItem>
                                        {FILTER_OPERATOR?.map((dataItem, i) => (
                                          <MenuItem
                                            key={i}
                                            value={dataItem.value}
                                          >
                                            {dataItem.name}
                                          </MenuItem>
                                        ))}
                                      </GKSelect>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <FormControl variant="standard" fullWidth>
                                      <GKTextField
                                        inputLabel="value"
                                        name={`filters.${index}.value`}
                                        value={values.filters[index].value}
                                        onChange={handleChange}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={2}
                                    display={"flex"}
                                    justifyContent={"center"}
                                    alignSelf={"center"}
                                    mt={2}
                                  >
                                    <IconButton
                                      color="error"
                                      disabled={values.filters?.length === 1}
                                      onClick={() => arrayHelper.remove(index)}
                                    >
                                      <RiDeleteBinLine />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              );
                            }
                          )}
                        </Grid>
                      );
                    }}
                  />
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </GKModal>
  );
}

export default ComanFilterModal;
