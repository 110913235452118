import Table from "components/Table";
import { TableColumn } from "components/Table/types";
import { data } from "./dummyData";

const columns: TableColumn<any>[] = [
  {
    name: "id",
    key: "id",
    show: false,
  },
  {
    name: "Portfolio",
    key: "portfolio",
    show: true,
  },
  {
    name: "Fee Plan",
    key: "feePlan",
    show: true,
  },
  {
    name: "Partners",
    key: "partners",
    show: true,
  },
  {
    name: "Revenue Collected",
    key: "revenueCollected",
    show: true,
  },
  {
    name: "Unbilled Revenue",
    key: "unbilledRevenue",
    show: true,
  },
  {
    name: "Last Billing Date",
    key: "lastBillingDate",
    show: true,
  },
  {
    name: "Approved",
    key: "approved",
    show: true,
  },
];

function FeeStructure() {
  return <Table columns={columns} data={data} paper />;
}

export default FeeStructure;
