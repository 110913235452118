import {
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import { useAppDispatch } from "hooks/useToolkit";
import React, { useState } from "react";
import { FiLogOut } from "react-icons/fi";
import { IoIosContact } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Import useLocation
import { AuthenticationActions } from "services/redux/slices/Authentication";
import { resetStates } from "services/redux/store/revertStates";
import { theme } from "utils/Theme";

interface DashboardLayoutProps {
  title: string;
  breadcrumbs?: {
    name: string;
    path: string;
  }[];
  children?: React.ReactNode;
  toolbar?: React.ReactNode;
}

export enum DashboardType {
  INVESTOR,
  ADVISOR,
  ASSOCIATE,
}
function DashboardLayout(props: DashboardLayoutProps) {
  const { toolbar, children } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const {
    state: { currentUser },
  } = useAppContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(resetStates());
    dispatch(AuthenticationActions.Logout());
    queryClient.clear();
    handleMenuClose();
  };

  const groups = [
    {
      name: "Dashboard",
      to: "/app/dashboard",
    },
    {
      name: "Users",
      to: "/app/dashboard/user-details",
    },
    {
      name: "Admin Access Panel",
      to: "/app/admin-access-board",
    },
    {
      name: "Company Master",
      to: "/app/company-master",
    },
    {
      name: "Broker Master",
      to: "/app/broker-master",
    },
    {
      name: "FAQs",
      to: "/app/card",
    },
    {
      name: "Communication",
      to: "/app/communication",
    },
  ];

  return (
    <Grid container>
      <Grid
        zIndex={3}
        item
        xs={12}
        container
        height={"79px"}
        justifyContent={"space-between"}
        alignItems={"center"}
        px={2}
        borderBottom={`solid 1px ${theme.palette.primary.main}33`}
        bgcolor={theme.palette.light.main}
        position={"sticky"}
        top={0}
      >
        <Link to="/app/dashboard" role="button">
          <img
            src={"/Logos/Gridkey_BrandGuidelines-removebg-preview.png"}
            draggable="false"
            alt=""
            style={{
              height: "50px",
              transition: "all .4s linear",
            }}
          />
        </Link>
        <br />
        <Grid item>
          <Typography
            fontWeight={700}
            fontSize={20}
            color={theme.palette.primary.main}
          >
            {groups
              .filter((button) =>
                currentUser?.phone === "7016333333" ||
                currentUser?.phone === "9911223344"
                  ? button
                  : button?.name !== "Company Master"
              )
              .map((dataItem) => {
                const isActive = location.pathname === dataItem.to;
                return (
                  <Button
                    key={dataItem.name}
                    size="large"
                    onClick={() => navigate(dataItem.to)}
                    sx={{
                      textDecoration: isActive ? "underline" : "none",
                      color: isActive
                        ? theme.palette.primary.main
                        : theme.palette.text.primary,
                      fontWeight: isActive ? 700 : 400,
                    }}
                  >
                    {dataItem.name}
                  </Button>
                );
              })}
          </Typography>
        </Grid>
        {toolbar ? (
          <Grid
            item
            display={"flex"}
            justifyItems={"center"}
            alignItems={"center"}
            gap={2}
          >
            {toolbar}
          </Grid>
        ) : (
          <div style={{ padding: "16px" }} />
        )}
        <Grid item>
          <IconButton onClick={handleMenuOpen}>
            <IoIosContact />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            sx={{
              "& .MuiPaper-root": {
                backgroundColor: theme.palette.background.paper,
                borderRadius: "10px",
                boxShadow: "0px 14px 120px rgba(0, 0, 0, 0.1)",
                padding: "15px",
                minWidth: "250px",
              },
            }}
          >
            <Grid
              container
              alignItems="center"
              justifyContent="space-around"
              spacing={1}
            >
              <Grid item>
                {currentUser?.profileImage ? (
                  <img
                    onError={({ currentTarget }) => {
                      currentTarget.src = "/Logos/errorImg.svg";
                    }}
                    style={{
                      height: "60px",
                      width: "60px",
                      background: `${theme.palette.warning.dark}11`,
                      borderRadius: "50%",
                    }}
                    alt="Profile"
                  />
                ) : (
                  <IoIosContact size={50} color={theme.palette.primary.main} />
                )}
              </Grid>
              <Grid item>
                <Typography
                  fontWeight={600}
                  fontSize={18}
                  textAlign="left"
                  width={"32vh"}
                  color={theme.palette.primary.main}
                >
                  {currentUser?.phone
                    ? currentUser.phone
                    : "No Phone Available"}
                </Typography>
              </Grid>
            </Grid>
            <MenuItem
              onClick={handleLogout}
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                padding: "10px 2px",
                mt: 3,
                color: `${theme.palette.error.main}`,
              }}
            >
              <FiLogOut size={20} />
              Logout
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
      <Grid zIndex={1} item xs={12} p={2} maxHeight={`calc(100vh - 79px)`}>
        {children}
      </Grid>
    </Grid>
  );
}

export default DashboardLayout;
