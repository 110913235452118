import {
  AutocompleteProps,
  CircularProgress,
  InputLabel,
  Autocomplete as MuiAutocomplete,
  Skeleton,
  styled,
} from "@mui/material";
import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { RiArrowDownSLine } from "react-icons/ri";
import { theme } from "utils/Theme";

interface GKSearchSelectProps {
  inputLabel?: string;
  requiredField?: boolean;
}

const Autocomplete = styled(MuiAutocomplete)(({ theme }) => ({
  background: theme.palette.light?.main,
  borderRadius: " 8px !important",
  "& .MuiIconButton-root": {
    border: "none",
  },
  "& .MuiInputBase-input": {
    padding: "1px !important",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: " 8px !important",
    borderColor: `${theme.palette.primary?.main} !important`,
  },
  "& .Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: " red !important",
  },
  "& .MuiFormHelperText-contained": {
    margin: ` 0 !important`,
  },
  "& .MuiAutocomplete-endAdornment .MuiIconButton-root": {
    background: ` none !important`,
    marginTop: 2,
  },
  "& .MuiAutocomplete-endAdornment .MuiIconButton-root:hover": {
    background: `none !important`,
    border: "none !important",
    color: "unset !important",
  },
  "& input::placeholder": {
    fontSize: "12px",
  },

  "& legend": {
    lineHeight: "8px",
  },
}));

const GKSearchSelect = (
  props: GKSearchSelectProps & AutocompleteProps<any, any, any, any, any>
) => {
  const {
    inputLabel,
    id,
    requiredField,
    loading,
    options,
    getOptionLabel,
    disableClearable = true,
  } = props;

  const [initialSliceValue, setInitialSliceValue] = useState(50);

  const [searchText, setSearchText] = useState("");

  const handleFilterOptions = (option: any) => {
    const normalizedSearchTerm = searchText
      ?.toString()
      ?.toLowerCase()
      ?.replace(/\s/g, "");

    const filteredData = option?.filter((company: any) =>
      Object.values(company).some((value) => {
        return (
          value !== null &&
          value
            ?.toString()
            ?.toLowerCase()
            ?.replace(/\s/g, "")
            ?.includes(normalizedSearchTerm)
        );
      })
    );

    return (
      [...new Set([...filteredData, ...options])]?.slice(
        0,
        initialSliceValue
      ) || []
    );
  };

  const handleScroll = (event: any) => {
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - position <= 1) {
      setInitialSliceValue(initialSliceValue + 100);
    }
  };

  return (
    <>
      {inputLabel && (
        <InputLabel
          style={{
            color: "#01657F",
            fontSize: "18px",
            lineHeight: 1,
            textTransform: "capitalize",
            fontFamily: "Inter !important",
            marginTop: 2,
          }}
          shrink
          htmlFor={id}
        >
          {inputLabel}
          {requiredField && (
            <span
              style={{
                color: theme.palette.error.main,
              }}
            >
              *
            </span>
          )}
        </InputLabel>
      )}
      {loading ? (
        <Skeleton
          variant="rectangular"
          animation="wave"
          sx={{
            height: 44,
            borderRadius: 2,
          }}
        />
      ) : (
        <Autocomplete
          {...props}
          disableClearable={disableClearable}
          getOptionLabel={getOptionLabel}
          ListboxProps={{
            onScroll: handleScroll,
          }}
          filterOptions={handleFilterOptions}
          options={handleFilterOptions(options)}
          onInputChange={(event, val) => {
            setSearchText(val);
          }}
          componentsProps={{
            popper: {
              placement: "bottom",
            },
          }}
          popupIcon={
            loading ? (
              <CircularProgress size={16} />
            ) : (
              <RiArrowDownSLine size={16} />
            )
          }
          ChipProps={{
            deleteIcon: <IoMdClose size={16} />,
          }}
        />
      )}
    </>
  );
};

export default GKSearchSelect;
