import { GridColDef } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import CommonPagination from "components/CommanPagination";
import GKTable from "components/GKTable";
import toast from "react-hot-toast";

import axiosInstance from "utils/axiosInstance";
import { extraColumnsField } from "utils/helpers";

const EmployeeTable = () => {
  const {
    state: { commonFilter },
  } = useAppContext();

  const requestBody = {
    take: commonFilter?.employee?.take,
    skip: commonFilter?.employee?.skip,
    search: commonFilter?.employee?.search || "",
    sort: JSON.stringify(
      commonFilter?.employee?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
  };

  const { data, isFetching } = useQuery({
    queryKey: ["DASHBOARD_EMPLOYEE_TABLE", requestBody],
    queryFn: () =>
      axiosInstance.get(`/super_admin/employees/`, {
        params: requestBody,
      }),
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Employee Name", "name", 150, 1),
      valueGetter: (row) => row?.row?.name || "-",
    },
    {
      ...extraColumnsField("Company Name", "companyName", 150, 1),
      valueGetter: (row) => row?.row?.companyName || "-",
    },
    {
      ...extraColumnsField("Phone Number", "phone", 150, 1),
      type: "number",
      valueGetter: (row) => row?.row?.phone || "-",
    },
    {
      ...extraColumnsField("Created on", "createdOn", 150, 1),
      // valueFormatter: (params) => dayjs(params?.value).format("DD MMM YYYY"),
      valueGetter: (row) => row?.row?.createdOn || "N/A",
    },
  ];

  const cusPagination = () => {
    return (
      <CommonPagination
        name="employee"
        totalCount={data?.data.totalCount || 0}
      />
    );
  };

  return (
    <GKTable
      loading={isFetching}
      columns={columns}
      name="employee"
      rows={data?.data?.data || []}
      CustomPagination={!isFetching && cusPagination}
    />
  );
};

export default EmployeeTable;
