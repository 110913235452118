import { FeeStructureProvider } from "./Components/Context";
import MainPage from "./Components/MainPage";

function ClonePage() {
  return (
    <FeeStructureProvider>
      <MainPage />
    </FeeStructureProvider>
  );
}

export default ClonePage;
