/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-shadow */
class Response<T> {
  constructor(
    public status: number,
    public data?: T,
    public errors?: any,
    public isLoading?: boolean
  ) {}
}

export const fetchData = async <T>(
  url: string,
  method: string,
  data: any,
  auth = true,
  json = true,
  token?: string
): Promise<Response<T>> =>
  fetch(`${url}`, {
    method,
    headers: {
      ...(["POST", "PUT", "PATCH"].includes(method) &&
        json && { "Content-Type": "application/json" }),
      ...(auth && {
        Authorization: `Token ${
          JSON.parse(localStorage.getItem("token")!) || token
        }`,
        "x-gridkey-user-role": "advisor", // TODO - Replace this with the current gridkey user role
      }),
    },
    ...(data && { body: json ? JSON.stringify(data) : data }),
  })
    .then((r) =>
      Promise.all([
        Promise.resolve(r.status),
        r.headers.get("Content-Type")?.includes("application/json")
          ? r.json()
          : r.text(),
      ])
    )
    .then(([status, data]) => {
      if (status >= 200 && status < 300) {
        return new Response<T>(status, data, undefined, false);
      }
      return new Response<T>(status, undefined, data);
    })
    .catch((err) => new Response<T>(999, undefined, err));

export const downloadFiles = async (
  url: string,
  data: any,
  method: "GET" | "POST"
) =>
  fetch(url, {
    method,
    headers: {
      Authorization: `Token ${JSON.parse(localStorage.getItem("token")!)}`,
      "x-gridkey-user-role": "advisor",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
