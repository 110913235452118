import { AccessList } from "interfaces/AccessList";
import { fetchData } from "utils/api";

export default {
  getAccessList: async () =>
    fetchData<AccessList[]>(
      `${process.env.REACT_APP_BASE_URL}/user/all_user/`,
      "GET",
      null,
      true
    ),
  grantAccess: async (accessData: {
    userPhoneNumber: string;
    hasAccess: boolean;
  }) =>
    fetchData<AccessList[]>(
      `${process.env.REACT_APP_BASE_URL}/user/${accessData?.userPhoneNumber}/grant_access/`,
      "POST",
      { grant_access: accessData.hasAccess }
    ),
};
