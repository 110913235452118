import {
  InputLabel,
  Select,
  SelectProps,
  Typography,
  styled,
} from "@mui/material";
import { theme as MuiTheme } from "utils/Theme";

interface GKSelectProps {
  inputLabel?: string;
  helperText?: string;
}

const BootstrapInput = styled(Select)(() => ({
  "label + &": {
    marginTop: 21,
  },

  "& .MuiInputBase-input": {
    borderRadius: "0px !important",
    position: "relative",
    border: "1px solid",
    borderColor: "#006580",
    fontSize: 12,
    padding: "10px 12px",
    background: "white",
  },
  "&  .MuiSvgIcon-root.MuiSelect-icon ": {
    marginRight: "8px !important",
    color: "#006580",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "0px !important",
    borderColor: "#006580 !important",
  },
}));

function GKSelect(props: GKSelectProps & SelectProps) {
  const { inputLabel, id, name, helperText, error } = props;
  return (
    <>
      {inputLabel && (
        <InputLabel
          style={{
            color: "#01657F",
            fontSize: "18px",
            textTransform: "capitalize",
          }}
          shrink
          htmlFor={name || id}
        >
          {inputLabel}
        </InputLabel>
      )}
      <BootstrapInput
        disableUnderline
        {...props}
        name={name}
        id={id}
        fullWidth
        error={error}
        displayEmpty
      />
      {helperText && (
        <Typography
          fontWeight={500}
          fontSize={12}
          color={MuiTheme.palette.error.main}
        >
          {helperText}
        </Typography>
      )}
    </>
  );
}

export default GKSelect;
