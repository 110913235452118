// API constants
export const COMPANY_MASTER_URL = process.env.REACT_APP_COMPANY_MASTER_URL
  ? process.env.REACT_APP_COMPANY_MASTER_URL
  : "http://localhost:5000";

// Application constants
export const TOKEN_KEY = "token";

export const BANK_ACCOUNT_TYPES = [
  { name: "Savings", value: "Savings" },
  { name: "Current", value: "Current" },
];

export const TRANSACTION_TYPES = [
  { name: "BUY", value: "BUY" },
  { name: "SELL", value: "SELL" },
  { name: "DIVIDEND", value: "DIVIDEND" },
  { name: "IPO", value: "IPO" },
  { name: "BUY BACK", value: "BUY_BACK" },
];
export const CASH_TRANSACTION_TYPES = [
  { name: "DEPOSIT", value: "DEPOSIT" },
  { name: "WITHDRAW", value: "WITHDRAW" },
];

export const SETTLEMENT_STATUS = [
  { name: "bse", value: "bse" },
  { name: "nse", value: "nse" },
];

export const PERSONAL_GENDER = [
  {
    name: "Male",
    value: "Male",
  },
  {
    name: "Female",
    value: "Female",
  },
];
export const MARKET_STATUS = [
  {
    name: "Married",
    value: "Married",
  },
  {
    name: "Unmarried",
    value: "Unmarried",
  },
];

export const NOMINEE_ADDED = [
  { name: "Yes", value: true },
  { name: "No", value: false },
];

export const UPLOAD_MANAGER_TEMPLATES = [
  { name: "GridKey template", value: "GridKey template" },
  { name: "Zerodha template", value: "Zerodha template" },
];
export const UPLOAD_MANAGER_FILE_TYPES = [
  { name: "CSV", value: "csv" },
  { name: "PDF", value: "pdf" },
];
export const UPLOAD_MANAGER_VERSIONS = [
  { name: "v1", value: "v1" },
  { name: "v2", value: "v2" },
  { name: "v3", value: "v3" },
];

export const PORTFOLIO_BILLING_CYCLE = [
  { name: "Yearly", value: "Yearly" },
  { name: "Year End", value: "Year End" },
];

// model portfolio constants
export const MODEL_PORTFOLIO_REBALANCING_TIME = [
  { name: "Monthly", value: "Monthly" },
  { name: "Quarterly", value: "Quarterly" },
  { name: "Half Yearly", value: "Half yearly" },
  { name: "Yearly", value: "Yearly" },
];

export const MODEL_PORTFOLIO_BENCCHMARK = [
  { name: "NIFTY 50", value: "NIFTY 50" },
  { name: "S&P BSE SENSEX", value: "S&P BSE SENSEX" },
  { name: "NIFTY MIDCAP 100", value: "NIFTY MIDCAP 100" },
  { name: "S&P BSE 500", value: "S&P BSE 500" },
  { name: "NIFTY 500", value: "NIFTY 500" },
];

export const INVESTMENT_STRATEGIES = [
  { name: "Asset Allocation", value: "Asset Allocation" },
  { name: "Factor Investing", value: "Factor Investing" },
  { name: "Quantitative ", value: "Quantitative" },
  { name: "Technical", value: "Technical" },
  { name: "Quality", value: "Quality" },
  { name: "Growth", value: "Growth" },
  { name: "Value", value: "Value" },
  { name: "Thematic", value: "Thematic" },
  { name: "Sector Tracker ", value: "Sector Tracker" },
  { name: "ESG", value: "ESG" },
  { name: "Corporate Governance", value: "Corporate Governance" },
  { name: "Quantamental", value: "Quantamental" },
  { name: "Fundamental", value: "Fundamental" },
  { name: "Momentum", value: "Momentum" },
  { name: "Goal Based", value: "Goal Based" },
  { name: "Dividend", value: "Dividend" },
];

export const ORGANIZATION_SELECT_OPTIONS = [
  { name: "Branch", value: "Branch" },
  { name: "Employee", value: "Employee" },
  { name: "Associate", value: "Associate" },
];

export const RADIO_BOX_TIME_PERIOD = [
  { name: "1D", value: "1D" },
  { name: "1M", value: "1M" },
  { name: "6M", value: "6M" },
  { name: "1Y", value: "1Y" },
  { name: "3Y", value: "3Y" },
  { name: "5Y", value: "5Y" },
  { name: "Max", value: "Max" },
];

export const LEAD_STATUS = [
  { value: "New Lead", name: "New Lead" },
  { value: "Contacted", name: "Contacted" },
  { value: "Qualified", name: "Qualified" },
  { value: "Unqualified", name: "Unqualified" },
  { value: "Converted", name: "Converted" },
  { value: "Lost", name: "Lost" },
  { value: "Follow-up", name: "Follow-up" },
  { value: "Not Interested", name: "Not Interested" },
  { value: "Pending", name: "Pending" },
  { value: "In Progress", name: "In Progress" },
  { value: "Closed", name: "Closed" },
  { value: "On Hold", name: "On Hold" },
  { value: "Hot Lead", name: "Hot Lead" },
  { value: "Cold Lead", name: "Cold Lead" },
  { value: "Warm Lead", name: "Warm Lead" },
];

export const LEAD_SOURCE = [
  { value: "Existing Customer", name: "Existing Customer" },
  { value: "Website", name: "Website" },
  { value: "Reference", name: "Reference" },
  { value: "Facebook", name: "Facebook" },
  { value: "Google", name: "Google" },
  { value: "Email", name: "Email" },
  { value: "Phone", name: "Phone" },
  { value: "Event", name: "Event" },
  { value: "Direct Mail", name: "Direct Mail" },
];

export const MEETING_TYPE = [
  { name: "Google Meet", value: "Google Meet" },
  { name: "Zoom", value: "Zoom" },
  { name: "Offline", value: "Offline" },
];

export const NOTES_TYPE = [
  { name: "Meeting Notes", value: "Meeting Notes" },
  { name: "Advice Notes", value: "Advice Notes" },
];

export const customAutoImportData = [
  {
    id: 1,
    title: "Gmail",
    img: "https://play-lh.googleusercontent.com/6UgEjh8Xuts4nwdWzTnWH8QtLuHqRMUB7dp24JYVE2xcYzq4HA8hFfcAbU-R-PC_9uA1",
    disabled: false,
  },
  {
    id: 2,
    title: "OutLook / Hotmail",
    img: "https://pbs.twimg.com/profile_images/1136345387181076481/0gjKiHmL.png",
    disabled: true,
  },
  {
    id: 3,
    title: "Other",
    img: "https://static.vecteezy.com/system/resources/previews/000/547/370/original/envelope-mail-icon-vector-illustration.jpg",
    disabled: true,
  },
];

export const UPLOAD_FILE_TYPES = [
  { name: "Stock", value: "Stock" },
  { name: "Cash", value: "Cash" },
  { name: "Portfolio", value: "Portfolio" },
];

export const TRANSACTIONS_UPLOAD_FILE_TYPES = [
  { name: "Stock", value: "Stock" },
  { name: "Cash", value: "Cash" },
];

export const INSIGHTS_TRANSACTIONS_PINS = [
  { name: "Buy", value: "Buy" },
  { name: "Sell", value: "Sell" },
  { name: "Buy & Sell", value: "Buy & Sell" },
];

export const colors = ["#006580", "#002d74", "#268588", "#2f76e980", "#25b174"];

export const LARGE_CAP_COLOR = "#355C7D";
export const MID_CAP_COLOR = "#00B8A9";
export const SMALL_CAP_COLOR = "#323232";

export const largeCapColors = [
  "#008000",
  "#228B22",
  "#006400",
  "#3D3D3D",
  "#008B8B",
  "#2E8B57",
  "#6B8E23",
  "#90EE90",
  "#98FB98",
  "#9ACD32",
];

export const mediumCapColors = [
  "#00008B",
  "#0000CD",
  "#1E90FF",
  "#6495ED",
  "#87CEFA",
  "#ADD8E6",
  "#B0C4DE",
  "#B0E0E6",
  "#87CEEB",
  "#6A5ACD",
];

export const smallCapColors = [
  "#8B4513",
  "#A0522D",
  "#CD853F",
  "#D2691E",
  "#DEB887",
  "#BC8F8F",
  "#A52A2A",
  "#800000",
  "#8B0000",
  "#964B00",
];

export const COMPANY_STATUS = [
  { name: "ACTIVE", value: "ACTIVE" },
  { name: "INACTIVE", value: "INACTIVE" },
  { name: "SUSPENDED", value: "SUSPENDED" },
];

export const COMPANY_INSTTYPE = [
  { name: "EQ", value: "EQ" },
  { name: "INDEX", value: "INDEX" },
  { name: "ETF", value: "ETF" },
  { name: "MUTUAL FUNDS", value: "MF" },
];

export const COMPANY_CAP = [
  { name: "Large", value: "Large" },
  { name: "Mid", value: "Mid" },
  { name: "Small", value: "Small" },
  { name: "NA", value: "NA" },
];

export const IDENTIFIER = [
  { name: "NSE", value: "NSE" },
  { name: "BSE", value: "BSE" },
  { name: "ISIN", value: "ISIN" },
  { name: "AMFI", value: "AMFI" },
  { name: "PRODCODE", value: "PRODCODE" },
  { name: "NSESCRIPTCODE", value: "NSESCRIPTCODE" },
];

export const IDENTIFIER_STATUS = [
  { name: "ACTIVE", value: "ACTIVE" },
  { name: "INACTIVE", value: "INACTIVE" },
];

export const CORPORATE_ACTION_TYPE = [
  { name: "Dividend", value: "DIV" },
  { name: "Split", value: "SPL" },
  { name: "Bonus", value: "BNS" },
  { name: "Merger", value: "MRG" },
  { name: "Demerger", value: "DMG" },
  { name: "Reverse Split", value: "RVS" },
  { name: "Right Issue", value: "RIGHTS" },
  { name: "REC", value: "REC" },
];

export const FILTER_OPERATOR = [
  { value: "icontains", name: "Icontains" },
  { value: "=", name: "Equals" },
  { value: "startsWith", name: "Starts With" },
  { value: "endsWith", name: "Ends With" },
  // { value: 'isEmpty', name: 'Is Empty' },
  // { value: 'isNotEmpty', name: 'Is Not Empty' },
  // { value: 'isAnyOf', name: 'Is Any Of' },
];

export const COMPANY_ETF_SUB_TYPE = [
  { name: "CashEquivalent", value: "CE" },
  { name: "NonCashEquivalent", value: "NCE" },
  { name: "NA", value: "NA" },
];

export const MUTUAL_FUND_SUB_TYPE = [
  { value: "MFD", name: "MUTUAL FUND Debt" },
  { value: "MFEQ", name: "MUTUAL FUND Equity" },
  { value: "MFY", name: "MUTUAL FUND Hybrid" },
];

export const INSTRUMENT_SUB_TYPE = [
  { value: "CE", name: "Cash Equivalent" },
  { value: "NCE", name: "Non Cash Equivalent" },
];
