/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unstable-nested-components */
import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Popper,
  Switch,
  TableContainer,
  TablePagination,
  Typography,
  styled,
} from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  useGridApiContext,
} from "@mui/x-data-grid";
import { DataGridPro, DataGridProProps } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { MdClose, MdExpandLess, MdExpandMore, MdSearch } from "react-icons/md";
import { TbFilterCog } from "react-icons/tb";
import { theme } from "utils/Theme";
import ClickOutside from "./ClickOutside";
import { CustomLoader, EmptyData } from "./DataGridUtils";
import GKTextField from "./GKTextField";

interface Props {
  headerComponent?: any;
  headerGridStyle?: any;
  showSearch?: boolean;
  columnsButton?: boolean;
  filterButton?: boolean;
  densityButton?: boolean;
  exportButton?: boolean;
  searchPlaceholder?: string;
  showFilters?: boolean;
  ref?: any;
  CustomPagination?: any;
  setFilterColumn?: any;
  filterColumn?: any;
  exportCsv?: any;
  maxHeight?: any;
  hideHeaderGrid?: any;
  showHeaderGrid?: any;
  zeroHolding?: any;
  showNonZero?: any;
  showFiltersButton?: any;
  setShowNonZero?: any;
  handleSortChange?: any;
  rightPinnes?: any;
  initialPageSize?: any;
  minHeight?: any;
}

const StyledGridToolbarColumnsButton = styled(GridToolbarColumnsButton)(
  ({ theme }) => ({
    background: `${theme.palette.primary.main}44`,
    padding: "8px  16px",
    minWidth: 150,
  })
);
const StyledGridToolbarFilterButton = styled(GridToolbarFilterButton)(
  ({ theme }) => ({
    background: `${theme.palette.primary.main}44`,
    padding: "8px  16px",
    minWidth: 150,
  })
);

const GKClientDataGrid = (props: DataGridProProps & Props) => {
  const {
    rows,
    headerComponent,
    showSearch = true,
    showFilters = true,
    showFiltersButton = true,
    loading,
    ref,
    columnsButton = true,
    exportCsv,
    maxHeight,
    showHeaderGrid = true,
    pagination = true,
    zeroHolding,
    showNonZero,
    setShowNonZero,
    handleSortChange,
    onRowClick,
    initialPageSize,
    minHeight,
  } = props;

  const [searchText, setSearchText] = useState("");

  const [isFocused, setIsFocused] = useState(false);

  const CustomToolbar = (pgProps: any) => {
    const { value, onInputChange } = pgProps;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);

    const id = open ? "simple-popper" : undefined;

    return (
      showHeaderGrid && (
        <Grid
          container
          justifyContent={"space-between"}
          p={1}
          alignItems={"center"}
          ref={ref}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 10,
              width: "100%",
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                gap: 4,
              }}
            >
              {showFiltersButton && (
                <IconButton
                  onClick={handleClick}
                  style={{
                    borderRadius: "8px",
                  }}
                >
                  <TbFilterCog size={20} />
                </IconButton>
              )}
              {showSearch && (
                <GKTextField
                  style={{ width: 250 }}
                  placeholder={"Search..."}
                  value={value}
                  sx={{
                    "& .MuiInputBase-root ": {
                      height: "35px",
                    },
                  }}
                  onChange={onInputChange}
                  type="search"
                  autoFocus={isFocused}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  InputProps={{
                    startAdornment: (
                      <MdSearch size={26} color={theme.palette.grey[500]} />
                    ),
                  }}
                />
              )}
            </span>
            {headerComponent && headerComponent}
          </div>

          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            placement="bottom-start"
            style={{
              zIndex: 99999,
            }}
          >
            <ClickOutside onClose={() => setAnchorEl(null)}>
              <Box
                sx={{
                  p: 2,
                  borderRadius: "0px",
                  bgcolor: "background.paper",
                  boxShadow: `-10px 10px 21px ${theme.palette.primary.main}44`,
                  border: `solid 1px ${theme.palette.primary.main}33`,
                  width: 350,
                }}
              >
                <IconButton
                  style={{
                    position: "absolute",
                    top: "-15px",
                    right: "-14px",
                  }}
                  color="error"
                  onClick={() => {
                    setAnchorEl(null);
                  }}
                >
                  <MdClose />
                </IconButton>

                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      fontWeight={600}
                      color={theme.palette.grey[700]}
                    >
                      Columns & Other Filters
                    </Typography>
                    <Divider
                      style={{
                        margin: "4px 0",
                      }}
                    />
                    {showFilters && (
                      <GridToolbarContainer>
                        {columnsButton && (
                          <StyledGridToolbarColumnsButton
                            onClick={() => setAnchorEl(null)}
                          />
                        )}
                        <StyledGridToolbarFilterButton
                          onMouseDownCapture={() => {
                            setTimeout(() => {
                              setAnchorEl(null);
                            }, 300);
                          }}
                        />
                      </GridToolbarContainer>
                    )}
                    <br />
                    {zeroHolding && (
                      <FormControlLabel
                        style={{
                          marginLeft: 2,
                        }}
                        control={
                          <Switch
                            onChange={(event) => {
                              setShowNonZero(event.target.checked);
                              setAnchorEl(null);
                            }}
                            checked={showNonZero}
                          />
                        }
                        labelPlacement="end"
                        label={
                          <b
                            style={{
                              fontSize: 12,
                            }}
                          >
                            Show zero holding
                          </b>
                        }
                      />
                    )}
                  </Grid>
                  {exportCsv && (
                    <Grid item xs={12}>
                      <Typography
                        fontWeight={600}
                        color={theme.palette.grey[700]}
                      >
                        Export Data
                      </Typography>
                      <Divider
                        style={{
                          margin: "4px 0",
                        }}
                      />
                      {exportCsv()}
                    </Grid>
                  )}
                </Grid>
              </Box>
            </ClickOutside>
          </Popper>
        </Grid>
      )
    );
  };

  const Pagination = (props: any) => {
    const apiRef = useGridApiContext();

    const handlePageChange = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
    ) => {
      apiRef.current.setPage(newPage);
    };

    const handleRowsPerPageChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      const newRowsPerPage = parseInt(event.target.value, 10);
      apiRef.current.setPageSize(newRowsPerPage);
    };

    return pagination ? (
      <TablePagination
        {...props}
        component="div"
        labelRowsPerPage="Rows"
        count={apiRef.current.getRowsCount()}
        page={apiRef.current.state.pagination.paginationModel.page}
        rowsPerPage={apiRef.current.state.pagination.paginationModel.pageSize}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPageOptions={initialPageSize ? [] : [25, 50, 100]}
      />
    ) : (
      <div> </div>
    );
  };

  const filteredRows =
    rows?.length !== 0 &&
    rows?.filter((row) => {
      return Object.values(row).some((value) => {
        return String(value).toLowerCase()?.includes(searchText.toLowerCase());
      });
    });

  const handleRowsReturn = () => {
    if (searchText) {
      return filteredRows;
    }
    if (loading) {
      return [];
    }
    return rows || [];
  };

  return (
    <TableContainer>
      <DataGridPro
        {...props}
        slots={{
          toolbar: CustomToolbar,
          noRowsOverlay: EmptyData,
          loadingOverlay: CustomLoader,
          detailPanelExpandIcon: () => <MdExpandMore size={20} />,
          detailPanelCollapseIcon: () => <MdExpandLess size={20} />,
          footer: Pagination,
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: initialPageSize || 25,
            },
          },
        }}
        slotProps={{
          toolbar: {
            value: searchText,
            onInputChange: (event: any) => setSearchText(event.target.value),
          },
        }}
        onSortModelChange={handleSortChange}
        rows={handleRowsReturn()}
        sx={{
          "& .MuiDataGrid-virtualScroller": {
            maxHeight: maxHeight || "calc(100vh - 256px)",
            minHeight: minHeight || "",
          },
          "&.MuiDataGrid-root .MuiTablePagination-root": {
            borderTop: `1px solid ${theme.palette.primary.main}44`,
            padding: "0 8px",
          },
          "& .MuiDataGrid-row": {
            "&:hover": {
              cursor: onRowClick ? "pointer" : "",
            },
          },
        }}
        pagination={pagination}
      />
    </TableContainer>
  );
};

export default GKClientDataGrid;
