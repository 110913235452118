import { Updater, useImmer } from "hooks/useImmer";
import { useEffect } from "react";
import { TableColumn } from "./types";

const useColumns = <T>(
  columns: TableColumn<T>[],
  saveColumnPrefs: string
): [boolean[], Updater<boolean[]>, TableColumn<T>[]] => {
  const getColumnPreferences = (): boolean[] => {
    const storageData = localStorage.getItem(saveColumnPrefs);
    if (storageData && (storageData !== "undefined" || "null" || "[]" || [])) {
      const items = JSON.parse(storageData);
      return columns.map((x) => items.includes(x.name));
    }
    return columns.map((x) => x.show !== false);
  };

  const [showColumns, setShowColumns] = useImmer(getColumnPreferences());
  const filteredColumns = columns.filter((col, index) => showColumns[index]);

  useEffect(() => {
    if (saveColumnPrefs) {
      localStorage.setItem(
        saveColumnPrefs,
        JSON.stringify(filteredColumns.map((x) => x.name))
      );
    }
  }, [filteredColumns]);

  return [showColumns, setShowColumns, filteredColumns];
};

export default useColumns;
