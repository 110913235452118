/* eslint-disable react/no-array-index-key */
import classNames from "classnames";
import { BiShow } from "react-icons/bi";

interface ItemsOption {
  name: string;
  value: any;
  portfolioName?: string;
}
interface Props {
  selected: string[];
  setSelected: (selected: string[]) => void;
  items: ItemsOption[];
  label?: string;
  className?: string;
  showSample?: boolean;
}
function CustomCheckBox(props: Props) {
  const { selected, setSelected, items, label, className, showSample } = props;

  return (
    <>
      <h2 className="mt-2">{label}</h2>
      <div className={classNames("flex flex-wrap", className)}>
        {items.map((item, itemIdx) => (
          <div key={itemIdx} className="relative flex mb-1 gap-2 items-center">
            <div className="ml-3 h-6">
              <input
                id={`item-${item.name}`}
                name={`item-${item.name}`}
                value={item.name}
                checked={selected.includes(item.value)}
                className="focus:ring-primary-dark h-6 w-6 text-primary-dark border-gray-300 rounded"
                type="radio"
                onChange={() => {
                  // const { checked } = e.target
                  // if (checked) {
                  //   setSelected([...selected, item.value])
                  // } else {
                  //   setSelected(selected.filter((s) => s !== item.value))
                  // }
                  setSelected([item.value]);
                }}
              />
            </div>
            <div className="text-sm font-medium flex justify-between w-full items-center hover:text-primary-dark">
              <label
                htmlFor={`item-${item.name}`}
                className={classNames("cursor-pointer")}
              >
                <div className="text-lg">
                  {item.name}
                  {item.portfolioName ? ` - ${item.portfolioName}` : ""}
                </div>
              </label>
              {showSample && (
                <BiShow
                  className="inline-block ml-4 hover:text-primary-semidark cursor-pointer"
                  size={25}
                  onClick={() => {}}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
export default CustomCheckBox;
