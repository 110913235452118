import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Switch,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { theme } from "utils/Theme";

interface Props {
  data: any;
  isFetched: boolean;
  refetch: () => void;
}

const Dividend = (props: Props) => {
  const { id } = useParams();

  const { data, isFetched, refetch } = props;

  const { mutate } = useMutation({
    mutationKey: ["UPDATE_BROKER"],
    mutationFn: (data: any) =>
      axiosInstance.patch(
        `/super_admin/${id}/broker_integration_status/`,
        data
      ),
    onSuccess: (response) => {
      refetch();
      toast.success(response?.data?.message || "Broker Update Successfully");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const formik = useFormik({
    enableReinitialize: isFetched,
    initialValues: {
      addDividendToLedger: data?.data?.data?.addDividendToLedger || false,
    },
    onSubmit: (values: any) => {
      mutate(values);
    },
  });

  return (
    <Accordion
      disableGutters
      style={{
        border: `solid 1px ${theme.palette.dark?.main}44`,
        borderRadius: "8px",
        boxShadow: "none",
        background: "none",
      }}
    >
      <AccordionSummary
        expandIcon={<GridExpandMoreIcon />}
        sx={{
          "& .MuiAccordionSummary-content": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          },
        }}
      >
        <Typography
          fontSize={16}
          fontWeight={600}
          textTransform={"none"}
          color={theme.palette.primary.main}
        >
          Ledger
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Switch
          checked={formik.values.addDividendToLedger}
          onChange={(event) => {
            mutate({ addDividendToLedger: event.target.checked });
          }}
        />
        <Typography
          fontSize={14}
          fontWeight={500}
          textTransform={"none"}
          color={theme.palette.dark?.main}
        >
          {data?.heading || "Include Dividend in ledger"}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default Dividend;
