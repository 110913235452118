export enum QueryKeys {
  ACCESS_LIST = "accessList",
  COMPANY_NAMES = "COMPANY_NAMES",
  CORPORATE_ACTIONS_LIST = "CORPORATE_ACTIONS_LIST",
  COMPANY_LIST = "COMPANY_LIST",
  PAGINATED_TABLE = "PAGINATED_TABLE",
  CORPORATE_ACTION_COMPANY_LIST = "CORPORATE_ACTION_COMPANY_LIST",
  BROKER_MASTER_LIST = "BROKER_MASTER_LIST",
  BROKER_MASTER_DETAILS_LIST = "BROKER_MASTER_DETAILS_LIST",
  UNIQUE_COMPANY = "UNIQUE_COMPANY",
}
