export enum DashboardType {
  INVESTOR,
  ADVISOR,
  ASSOCIATE,
}

export const token = localStorage.getItem("token");

export const getUsetType = (userInfo: any) => {
  if (userInfo?.userType === DashboardType.INVESTOR) {
    return "client";
  }
  return "advisor";
};
