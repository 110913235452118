import { useState } from "react";

const usePagination = <T>(data: T[], length: number) => {
  const [currentPage, setCurrentPage] = useState(0);

  const current = data.slice(
    currentPage * length,
    currentPage * length + length
  );

  return {
    currentPage,
    setCurrentPage,
    currentData: current,
    totalPages: Math.ceil(data.length / length),
  };
};

export default usePagination;
